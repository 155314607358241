import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserRegister, ResendOtp, VerifyOtp, ClearResendOtp } from '../../../actions/user';
import OtpTimer from 'otp-timer'
import Utils from '../../../utils';
import { Drawer, Alert, Button, Col, Row, Input, Select, DatePicker, Space } from 'antd';
import Config from '../../../ev-config';
import validator from 'validator';

function Register (props) {
    const dispatch = useDispatch();
    const [name, setName] = useState("")
    const [username, setUsername] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [otp, setOtp] = useState("")

    const [initialSet, setInitialSet] = useState(false)
    const [timerOn, setTimerOn] = useState(false)
    const user = useSelector(store => store.user);

    const registerUser = () => {
        var payload = {
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            username: username,
            first_name : name,
            password: password,
            repeat_password: repeatPassword,
            mobile: "+91" + phoneNumber
        }
        dispatch(UserRegister(payload))
    }

    const getErrorDescription = () => {
        return (
            <div>
                {
                    user.regResp.errors.map((error) => {
                        return (<><span>{error}</span><br/></>)
                    })
                }
            </div>
        )
    }
    const resendOtp = () => {
        var payload = {
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            mobile: "+91" + phoneNumber
        }
        dispatch(ResendOtp(payload))
    }
    const verifyOtp = () => {
        var payload = {
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            otp: otp
        }
        dispatch(VerifyOtp(payload))
    }

    const triggerSetPhoneNumber = (ph) => {
        if((validator.isInt(ph) || !ph) && ph.length < 11 ) setPhoneNumber(ph)
    }

    if(user.isResendOtpComplete === 'FULFILLED') {
        dispatch(ClearResendOtp())
        setTimerOn(true)
        timer(120)
    }

    function timer(remaining) {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;
        
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        if(document.getElementById('timerReg'))
        document.getElementById('timerReg').innerHTML = m + ':' + s;
        remaining -= 1;
        if(remaining >= 0) {
          setTimeout(function() {
              timer(remaining);
          }, 1000);
          return;
        } else {
          setTimerOn(false)
        }
    } 

    if(user.isUserRegisterComplete === 'FULFILLED' && !initialSet) {
        setInitialSet(true)
        setTimerOn(true)
        timer(120)
    }

    return (
        <div>
            {user.isUserRegisterComplete === 'FULFILLED' ?
            <>
                <div className="mt-2" >
                    <Input value={otp} onChange={(e)=> setOtp(e.target.value)} size="large" placeholder="Enter your OTP" />
                </div>
                <div>
                    <div className="d-flex" >
                        <Button  onClick={()=> verifyOtp()} className="mt-2" type="primary">
                        { user.isVerifyOtpComplete === 'PENDING' && Utils.loader()} Submit
                        </Button>

                        {timerOn ?
                        <div className="mt-3 ml-4" >Time left = <span id="timerReg"></span></div>
                        :
                        <Button  onClick={() => resendOtp()}  className="mt-2 ml-4" type="primary">
                        { user.isResendOtpComplete === 'PENDING' && Utils.loader()} Resend OTP
                        </Button>
                        }
                    </div>

                    {timerOn &&
                        <Alert
                            className="mt-4"
                            message=""
                            description="An OTP send to your registered mobile number"
                            type="info"
                            showIcon
                        />
                    }
                </div>
            </>
            :
            <>  
                <div className="mt-2" >
                    <Input value={name} onChange={(e)=> setName(e.target.value)} size="large" placeholder="Name" />
                </div>
                <div className="mt-2" >
                    <Input value={username} onChange={(e)=> setUsername(e.target.value)} size="large" placeholder="Username" />
                </div>
                <div className="mt-2" >
                    <Input value={password} onChange={(e)=> setPassword(e.target.value)} type="password" size="large" placeholder="Password" />
                </div>
                <div className="mt-2" >
                    <Input value={repeatPassword} onChange={(e)=> setRepeatPassword(e.target.value)} type="password" size="large" placeholder="Repeat password" />
                </div>
                <div className="mt-2" >
                    <Input addonBefore="+91"  value={phoneNumber} onChange={(e)=> triggerSetPhoneNumber(e.target.value)}  size="large" placeholder="Mobile" />
                </div>
            
                <div>
                    <Button onClick={()=> registerUser()} className="mt-2" type="primary">
                       { user.isUserRegisterComplete === 'PENDING' && Utils.loader()} Submit
                    </Button>
                    {user.isUserRegisterComplete === 'REJECTED' &&
                        <Alert
                            className="mt-2"
                            message=""
                            description={getErrorDescription()}
                            type="error"
                            showIcon
                        />
                    }
                </div>
            </>
            }
            <div className="my-4 text-center" >
                OR
            </div>
            
            <div>If you have an account? <a onClick={() => props.setpageName('LOGIN')} className="ml-2">Login</a></div>
        </div>
    )
}

export default Register;