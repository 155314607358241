import React, { useRef } from "react";
import "./menuStyles.css";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import {
	Link,
	useHistory,
} from "react-router-dom";
import Config from '../../ev-config';


export default function App(props) {
	const history = useHistory();
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
	const onClick = () => {
		setIsActive(!isActive);
		history.push('/' + 'brand/' + props.data.title);
	}
	const onClose = (path) => {
		history.push(path);
		setIsActive(false);
	}

  	return (
		<div className="menu-container">
			<img  onClick={onClick} src={Config.cdnUrl + props.data.image} class="img-fluid" />
			{/* {props.data.cars > 0 && props.data.trucks > 0 &&
			<nav ref={dropdownRef} className={`menu ${isActive ? "active" : "inactive"}`} >
				<ul>
					{props.data.cars > 0 &&
					<li onClick={() => onClose('/cars/' + props.data.title)} >
						<span>Cars ({props.data.cars})</span>
					</li>
					}
					{props.data.trucks > 0 &&
					<li onClick={ () => onClose('/trucks/' + props.data.title)} >
						<span>Trucks ({props.data.trucks})</span>
					</li>
					}
				</ul>
			</nav>
			} */}
		</div>
  	);
}
