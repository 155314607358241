import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import renderHTML from 'react-render-html';
import { FetchEvNews } from '../../actions/vehicles';
import {
    Link,
    useHistory
} from "react-router-dom";

function  News(props) {
    const history = useHistory();
    const dispatch = useDispatch();
  
    var evNews = useSelector(store => store.vehicles.evNews);
    const [index, setIndex] = useState(0)

    useEffect(() => {
        if(props.apiFetch !== false) {
            dispatch(FetchEvNews(props.type))
        }
    }, []);

    if(props.apiFetch === false) {
        evNews = props.evNews
    }


    if(evNews.length === 0) {
        return <div></div>
    }

    return (
        <div class="up-events">
            <div class={`${props.fullWidth === true ? 'container-fluid' : 'container'}`}>
                <div className="row py-3" >
                    <div class="col-12">
                        <h4 class="title">Latest EV News</h4>
                            <div class="row position-relative ">
                            {index+4 < evNews.length &&
                            <i onClick={() => setIndex(index + 1)} class="fa fa-chevron-circle-right blox-next-icon pointer" aria-hidden="true"></i>
                            }
                            {index > 0 &&
                            <i onClick={() => setIndex(index - 1)} class="fa fa-chevron-circle-left blox-previous-icon pointer" aria-hidden="true"></i>
                            }
                            {getNews()} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function getNews() {
        var list = []
        for (var i=index; i<index+4 && i < evNews.length; i++) {
            if(evNews.length) {
                const a = i
                list.push(
                    <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pointer" id={evNews[a].title}  href={evNews[a].link} onClick={() => window.open(evNews[a].link,'_blank')}  >
                        <div className="blog mt-3">
                            <div className="blog-img">
                                <img alt="loading" className="category-img" src={evNews[a].image}/>
                            </div >
                            <div class="blog-desc">
                                <div class="blog-title white">{renderHTML(evNews[a].title)}</div>
                            </div>
                        </div>
                    </article>
                )
            }
        }
        return list;
    }
}

export default News;