import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    useParams,
    useHistory,
} from "react-router-dom";
import _ from 'lodash';
import UpcomingActivities from './category/upcomingActivities';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { FetchTruckList, FetchMakeList } from '../actions/vehicles';
import Config from '../ev-config';

var filterData = [];

function TruckList(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let { brand } = useParams();
    const history = useHistory();
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [selMakes, setSelMakes] = useState([])
    const [selBodyStyles, setSelBodyStyles] = useState([])
    const [selAvailability, setSelAvailability] = useState([])
    const [dataFetched, setDataFetched] = useState(false)
    const vehicle = useSelector(store => store.vehicles);
    
    useEffect(() => {
        if(history.location.pathname === '/trucks') {
            setSelMakes([])
            setSelBodyStyles([])
            setSelAvailability([])
        }
    }, [history.location.pathname])
    

    if(!dataFetched) {
        dispatch(FetchTruckList())
        dispatch(FetchMakeList('truck'))
        setDataFetched(true)

        // var urlParams = new URLSearchParams(history.location.search);
        // urlParams.get('brand'))

        if(typeof brand !== 'undefined') {
            setSelMakes([brand])
        }
    }
    
    
    var makeOptions = [];
    vehicle.makes.map((o, i) => {
        if(o.trucks > 0) {
            makeOptions.push({
                label: o.title,
                value: i,
            })
        }
    })
    makeOptions = _.sortBy(makeOptions, [function(o) { return o.label; }]);

    var bodyStyleOptions = []
    _.uniqBy(vehicle.trucks, v => v.shape).map((o, i) => {
        bodyStyleOptions.push({
            label: o.shape,
            value: i,
        })
    })
    bodyStyleOptions = _.sortBy(bodyStyleOptions, [function(o) { return o.label; }]);

    var availabilityOptions = [{
        label: `Available ( ${_.filter(vehicle.trucks, function(o) { return o.is_available }).length} )`,
        value: 'is_available'},
    {
        label: `Upcoming ( ${_.filter(vehicle.trucks, function(o) { return o.is_upcoming }).length} )`,
        value: 'is_upcoming',},
    {
        label: `Concept ( ${_.filter(vehicle.trucks, function(o) { return o.is_concept }).length} )`,
        value: 'is_concept',
    }]

  
    return (
        <section class="main">
        <div class="container seperator-bottom">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    
                    <div class="row brands pt-4 ml-0">
                        <div class="mr-3 mb-2">
                            <ReactMultiSelectCheckboxes 
                                width="200px"
                                placeholderButtonLabel="Make"  
                                theme={theme => ({
                                    ...theme,
                                    // borderRadius: 0,
                                    colors: {
                                        // ...theme.colors,
                                        primary25: 'black',
                                        primary: 'black',
                                    },
                                })} 
                                options={makeOptions} 
                                onChange={(o) => makeSelectChange(o)}
                            />
                        </div>
                        <div class="mr-3 mb-2">
                            <ReactMultiSelectCheckboxes 
                                width="200px"
                                placeholderButtonLabel="Body shape"  
                                theme={theme => ({
                                    ...theme,
                                    // borderRadius: 0,
                                    colors: {
                                        // ...theme.colors,
                                        primary25: 'black',
                                        primary: 'black',
                                    },
                                })} 
                                options={bodyStyleOptions} 
                                onChange={(o) => bodyStyleSelectChange(o)}
                            />
                        </div>
                        <div class="mr-3 mb-2">
                            <ReactMultiSelectCheckboxes 
                                width="200px"
                                placeholderButtonLabel="Availability"  
                                theme={theme => ({
                                    ...theme,
                                    // borderRadius: 0,
                                    colors: {
                                        // ...theme.colors,
                                        primary25: 'black',
                                        primary: 'black',
                                    },
                                })} 
                                options={availabilityOptions} 
                                onChange={(o) => availabilitySelectChange(o)}
                            />
                        </div>
                    </div>
                    
              </div>

                <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 seperator">
                    <div class="py-5 pr-2">
                        <h4 class="title">Compare electric vehicles/ Electric truck comparison</h4>
                        <p class="sub-title">Here are some links to <em>electric vehicles</em> on the market today – or soon to be on the market</p>
                        {getArticle()}  
                        {vehicle.trucks.length &&
                        <div class="pagination">
                            {loadPagination()}
                        </div>
                        }
                        
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="py-5 pl-2">
                        <h4 class="title">Top EV Brands</h4>
                        {/* <p class="sub-title">What are the world’s most popular electric truck brands?Tesla topped the world as the best-selling electric vehicle brand</p> */}
                        <div class="row brands pt-4">
                            {
                                _.sortBy(vehicle.makes, [function(o) { return o.name; }]).map((o, i) => {
                                    if(o.trucks > 0) {
                                        return <div key={o.image} class="col-md-6 col-sm-6 col-xs-6 col-4 mb-4">
                                                <img onClick={() => triggerBrandClick(o)} src={Config.cdnUrl + o.image} class="img-fluid" />
                                            </div>
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
              
        {/* <UpcomingActivities /> */}
    </section>
    )

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    function makeSelectChange(sm){
        var makeList = []
        sm.map((o) => {
            makeList.push(o.label)
        })
        setSelMakes(makeList)
        setPage(1)
    };
    function bodyStyleSelectChange(sm){
        var bsList = []
        sm.map((o) => {
            bsList.push(o.label)
        })
        setSelBodyStyles(bsList)
        setPage(1)
    };
    function availabilitySelectChange(sm){
        var avList = []
        sm.map((o) => {
            avList.push(o.value)
        })
        setSelAvailability(avList)
        setPage(1)
    };
    function triggerBrandClick(brand) {
        var makeList = []
        setSelMakes([brand.title])
        setPage(1)
        window.scrollTo(0, 0)
    }


    function loadNextPage(p) {
        setPage(p)
        window.scrollTo(0, 0)
    }
                        
    function loadPagination() {
        if(filterData.length >10) {
            var length = Math.ceil(filterData.length / 10)
            if(length <= 0 ) length = 1
            return Array.from(Array(length), (e, i) => {
                return (<span class={`${i+1 === page ? 'active' : ''}`}  onClick={() => loadNextPage(i+1)}  >{i + 1}</span>)
            })
        }
    }

    function getConceptText(data) {
        if(data.is_upcoming) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Upcoming</span>
        } else if(data.is_concept) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Concept</span>
        } 
        
    }
    
    function getArticle() {
        filterData = vehicle.trucks
        if(selMakes.length) filterData =  _.filter(vehicle.trucks, function(o) { return selMakes.indexOf(o.make) !== -1; });
        if(selBodyStyles.length) filterData =  _.filter(filterData, function(o) { return selBodyStyles.indexOf(o.shape) !== -1; });
     
        if(props.searchkey.length) {
            var newList = []
            var DELIMETER = '~!~';
            filterData.forEach((data) => {
                var combinedStr = data.title + DELIMETER + 
                data.sub_title  + DELIMETER + data.model + 
                DELIMETER + data.make + DELIMETER + data.efficiency;
                if(combinedStr.toLowerCase().indexOf(props.searchkey.toLowerCase()) !== -1) {
                    newList.push(data);
                }
            });
            filterData = newList
        }

        if(selAvailability.indexOf('is_available') !== -1 || selAvailability.indexOf('is_upcoming') !== -1 || selAvailability.indexOf('is_concept') !== -1) {
            filterData =  _.filter(filterData, function(o) { return (o.is_available && selAvailability.indexOf('is_available') !== -1) || (o.is_upcoming && selAvailability.indexOf('is_upcoming') !== -1) || (o.is_concept && selAvailability.indexOf('is_concept') !== -1) });
        }
        
        
        return filterData.map((data, i) => {
            if(i < page * 10 && i >= ((page -1) * 10) )
            return (
                <article key={data.uuid} >
                    <div class="blog">
                        <div class="blog-img">
                            <Link to={`/trucks/${data.make}/${data.title}/${data.uuid}/details`}><img src={Config.cdnUrl + data.preview_images[data.preview_images.length > 1 ? 1 : 0]}/></Link>
                        </div>
                        <div class="blog-desc">
                            <div class="blog-title"><a href={`/trucks/${data.make}/${data.title}/${data.id}/details`}>{data.title}</a> {getConceptText(data)}</div>
                            <p class="blog-date">{data.sub_title}</p>
                            {/* <p class="white blog-description">{data.specifications.model}</p> */}
                            {/* <table>
                                <tr>
                                    <td>0 - 100</td><td>Top Speed</td><td> Range</td><td>Efficiency</td><td>Fastcharge</td>
                                </tr>
                                <tr>
                                    <td>{data.specifications.acceleration}</td><td>{data.specifications.topspeed}</td><td>{data.specifications.erange_real}</td><td>{data.specifications.efficiency}</td><td>{data.specifications.fastcharge_speed}</td>
                                </tr>
                            </table> */}
                            
                        </div>
                    </div>
                </article>
            )
        })
    }
}

export default TruckList;