import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../ev-config';

import { FetchEvNews } from '../../actions/vehicles';
import {
    Link,
    useHistory
} from "react-router-dom";

function  Vehicles(props) {
    const history = useHistory();
    const dispatch = useDispatch();
  
    const vehicles  = props.vehicles

    return (
        <div class="up-events">
            <div class={`${props.fullWidth === true ? 'container-fluid' : 'container'}`}>
                <div class="row">
                    <div class="py-3">
                        <h4 class="title">Available Vehicles</h4>
                            <div class="row position-relative ">
                            {getVehicles()} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function getVehicles() {
        return vehicles.map((v) => {
            return (
                <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pointer" id={v.title} onClick={() => history.push(`/cars/${v.make}/${v.uuid}/details`)} >
                    <div className="blog mt-3">
                        <div className="blog-img">
                            <img alt="loading" className="category-img" src={Config.cdnUrl + v.thumbnail}/>
                        </div >
                        <div class="blog-desc">
                            <div class="blog-title white">{v.title}</div>
                        </div>
                    </div>
                </article>
            )
        })
    }
}

export default Vehicles;