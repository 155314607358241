import events from '../../actions/event-names';
import Config from '../../ev-config';
import Utils from '../../utils';

const regState = {
    isUserRegisterComplete: false,
    regResp: "",
}

const resendOtpState = {
    resendOtpError: "",
    isResendOtpComplete: false,
}


const initialState = {
    ...regState,
    ...resendOtpState,
    isCheckLoginComplete: false,
};

function getHeader(action) {
    return {
        authorization: ['Bearer', action.payload.data.access_token].join(' '),
    };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

    // CHECK LOGIN
    case `${events.CHECK_LOGIN}_PENDING`: {
        return {
            ...state,
            isCheckLoginComplete: 'PENDING',
        };
    }
    case `${events.USER_LOGIN}_FULFILLED`:
    case `${events.VERIFY_OPT}_FULFILLED`: 
    case `${events.CHECK_LOGIN}_FULFILLED`: {
        if(action.type === `${events.USER_LOGIN}_FULFILLED`) {
            state.isUserLoginComplete = 'FULFILLED'
        }
        if(action.type === `${events.VERIFY_OPT}_FULFILLED`) {
            Utils.showSuccess("OTP verified successfully");
            state.isVerifyOtpComplete = 'FULFILLED'
        }

        if(!action.payload.data.mobile_verified) {
            return {
                ...state,
                checkLoginResp: action.payload.data,
            }
        }
        return {
            ...state,
            checkLoginResp: action.payload.data,
            isCheckLoginComplete: 'FULFILLED',
            headers: {
                ...getHeader(action),
            },
        };
    }
    case `${events.CHECK_LOGIN}_REJECTED`: {
        return {
            ...state,
            isCheckLoginComplete: 'REJECTED',
        };
    }


     // CHECK LOGIN DELETE (LOGOUT)
    case `${events.LOGOUT_USER}_PENDING`: {
        return {
            ...state,
            isLogoutComplete: 'PENDING',
        };
    }
    case `${events.LOGOUT_USER}_FULFILLED`: {
        return {
            ...initialState,
            isLogoutComplete: 'FULFILLED',
        };
    }
    case `${events.LOGOUT_USER}_REJECTED`: {
        return {
            ...state,
            isLogoutComplete: 'REJECTED',
        };
    }
    

    // REGISTRATION
    case `${events.USER_REGISTER}_PENDING`: {
        return {
            ...state,
            isUserRegisterComplete: 'PENDING',
        };
    }
    case `${events.USER_REGISTER}_FULFILLED`: {
        return {
            ...state,
            isUserRegisterComplete: 'FULFILLED',
        };
    }
    case `${events.USER_REGISTER}_REJECTED`: {
        return {
            ...state,
            regResp: action.payload.response.data,
            isUserRegisterComplete: 'REJECTED',
        };
    }
    case `${events.CLEAR_USER_REGISTER}`: {
        return {
            ...state,
            ...regState,
            ...resendOtpState,
        };
    }


    // USER LOGIN 
    case `${events.USER_LOGIN}_PENDING`: {
        return {
            ...state,
            isUserLoginComplete: 'PENDING',
        };
    }
    case `${events.USER_LOGIN}_FULFILLED`: {
        return {
            ...state,
            isUserLoginComplete: 'FULFILLED',
        };
    }
    case `${events.USER_LOGIN}_REJECTED`: {
        if(action.payload.response) {
            Utils.showError(action.payload.response.data.error);
        }
        return {
            ...state,
            isUserLoginComplete: 'REJECTED',
        };
    }



    //RESEND OTP
    case `${events.RESEND_OPT}_PENDING`: {
        return {
            ...state,
            isResendOtpComplete: 'PENDING',
        };
    }
    case `${events.RESEND_OPT}_FULFILLED`: {
        return {
            ...state,
            isResendOtpComplete: 'FULFILLED',
        };
    }
    case `${events.RESEND_OPT}_REJECTED`: {
        if(action.payload.response) {
            Utils.showError(action.payload.response.data.error);
        }
        return {
            ...state,
            resendOtpError: action.payload.response.data.error,
            isResendOtpComplete: 'REJECTED',
        };
    }
    case `${events.CLEAR_RESEND_OPT}`: {
        return {
            ...state,
            resendOtpError: "",
            isResendOtpComplete: false
        };
    }



    //VERIFY OTP
    case `${events.VERIFY_OPT}_PENDING`: {
        return {
            ...state,
            isVerifyOtpComplete: 'PENDING',
        };
    }
    case `${events.VERIFY_OPT}_FULFILLED`: {
        Utils.showSuccess("You have successfully registered");
        return {
            ...state,
            isVerifyOtpComplete: 'FULFILLED',
        };
    }
    case `${events.VERIFY_OPT}_REJECTED`: {
        Utils.showError(action.payload.response.data.error);
        return {
            ...state,
            isVerifyOtpComplete: 'REJECTED',
        };
    }


     //BOOK TEST RIDE
     case `${events.BOOK_TEST_RIDE}_PENDING`: {
        return {
            ...state,
            isBookTestRideComplete: 'PENDING',
        };
    }
    case `${events.BOOK_TEST_RIDE}_FULFILLED`: {
        Utils.showSuccess("You test ride request send successfully");
        return {
            ...state,
            isBookTestRideComplete: 'FULFILLED',
        };
    }
    case `${events.BOOK_TEST_RIDE}_REJECTED`: {
        if(action.payload.response) {
            Utils.showError(action.payload.response.data.error);
        }
        return {
            ...state,
            isBookTestRideComplete: 'REJECTED',
        };
    }
    case `${events.CLEAR_BOOK_TEST_RIDE}`: {
        return {
            ...state,
            isBookTestRideComplete: false,
        };
    }



    default:
        return state;
    }
}
