function   upcomingActivities() {
    return (
        <div class="up-events">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-xs-12 seperator">
                        <div class="py-5 pr-2">
                            <img src="assets/images/ad.jpg" class="img-fluid"/>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <div class="py-5 pl-2">
                            <h4 class="title">Upcoming EV Cars</h4>
                            <p class="sub-title">Every <em>Electric Vehicle</em> That’s Expected in the Next Years. Electric cars are the future, and each year we’ve seen automakers add more EVs to their lineup</p>
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <article>
                                        <div class="blog">
                                            <div class="blog-img">
                                                <img src="assets/images/blog-4.png"/>
                                            </div>
                                            <div class="blog-desc">
                                                <div class="blog-title"><a href="">Tesla driver accused of sleeping on Autopilot is going to trial for dangerous driving</a></div>
                                                <p class="blog-date">December 11, 2020  11:20 am</p>
                                                <p class="white blog-description">Tesla driver accused of sleeping on Autopilot is going to trial for dangerous driving A</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <article>
                                        <div class="blog">
                                            <div class="blog-img">
                                                <img src="assets/images/blog-5.png"/>
                                            </div>
                                            <div class="blog-desc">
                                                <div class="blog-title"><a href="">BMW’s 2021 luxury sedan, the 745e PHEV, comes with 16 pure-electric miles</a></div>
                                                <p class="blog-date">December 29, 2020  12:32 pm</p>
                                                <p class="white blog-description">BMW’s 2021 luxury sedan, the 745e PHEV, comes with 16 pure-electric miles The BMW 7</p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div class="pagination float-right">
                                <a href="#" class="active">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default upcomingActivities;