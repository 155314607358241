import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useHistory,
    Redirect,
} from "react-router-dom";
import axios from 'axios';
import Profile from './profile';
import Menus from '../../components/menu/menu';
import { Input, Select } from 'antd';
import { FetchHomeList } from '../../actions/vehicles';
import Location from './location';
import Utils from '../../utils';
import Config from '../../ev-config';



const { Search } = Input;
const { Option } = Select;



function Header (props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [options, setOptions] = useState({vehicles: [], showrooms: []})

    useEffect(() => {
        axios.get(`${Config.apiRoot}/home/search?search=a`).then((resp)=> {
            setOptions(resp.data)
        })
    }, []);

    function onChange(value, obj) {
        if(obj.props.type === 'vehicle') {
            history.push(`/${obj.props.vehicle_type}s/${obj.props.make}/${obj.props.title}/${obj.props.uuid}/details`)
        } else if(obj.props.type === 'showroom') {
            history.push(`/showrooms/${obj.props.uuid}`)
        }
    }
    
    function onSearch(value) {
        if(value) {
            axios.get(`${Config.apiRoot}/home/search?search=${value}`).then((resp)=> {
                setOptions(resp.data)
            })
        }
    }

    return (
        <header> 
            <div class="container-fluid px-0">
                <div class="header-top">
                    <div class="container-fluid">
                        <div class="logo-block">
                            <div class="logo">
                                <a href="/"><img src="/assets/images/cropped-evhorse-logo-1-1.png" width="160"/></a>
                            </div>
                        </div>
                        
                        <Profile />

                        

                        {/* <i onClick={() => history.push('/find-your-chargin-stations')} title="Find your charging station" style={{fontSize: 34}} class="fa fa-plug float-right mt-2 px-2 pointer"></i> */}
                        
                        <Location />
                        <Menus />
                    
                        <div class="header-rtop">
                            <div className="main-menu" >
                                <Link to="/bikes" ><span  className={`item title`} >Bikes & Scooters</span></Link>
                                <Link to="/autos" ><span  className={`'item title ${window.location.href.includes('/autos') ? 'active' : ''}'`} >Autos</span></Link>
                                <Link to="/cars" ><span  className={`'item title ${window.location.href.includes('/cars') ? 'active' : ''}'`} >Cars</span></Link>
                                <Link to="/find-your-chargin-stations" ><span  className={`'item title ${window.location.href.includes('/find-your-chargin-stations') ? 'active' : ''}'`} >Charging stations</span></Link>
                                {/* <Link to="/trucks" ><span  className={`item title`} >Trucks</span></Link> */}
                            </div>
                            
                                {/* <div class="menu icon">
                                    <i class="fa fa-bars"></i>
                                    </div> */}
                                
                            <div>
                                {/* <Input.Search onChange={onSearch}   style={{ width: '200px' }}  /> */}

                            <Select
                                size="large"
                                showSearch
                                className="header-common-search"
                                style={{ width: "285px"}}
                                placeholder="Search"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                suffixIcon={<i class="fa fa-search"></i>}
                            >
                                {
                                    options.vehicles.map((o) => {
                                        o.type = 'vehicle'
                                        return <Option props={o} value={o.uuid}>{o.title}{' ('}{o.make}{')'}</Option>
                                    })
                                }
                                {
                                    options.showrooms.map((o) => {
                                        o.type = 'showroom'
                                        return <Option props={o} value={o.uuid}>{o.title}{' (Showroom)'}</Option>
                                    })
                                }
                            </Select>
                            
                            </div>

                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;