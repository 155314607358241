import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import _ from "lodash";
import ImageGallery from 'react-image-gallery';
import { FetchChargingStationDetails } from '../actions/vehicles';
import Config from '../ev-config';
import Loading from '../components/loading';
import MapPicker from 'react-google-map-picker'

function ChargingStationDetails(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const dispatch = useDispatch();
    let { id } = useParams();
    const chargingStationDetails = useSelector(store => store.vehicles.chargingStationDetails);

    const [zoom, setZoom] = useState(15);

    useEffect(() => {
        dispatch(FetchChargingStationDetails(id))
    }, [id])

    if(!chargingStationDetails) {
        return <section class="banner"><div class="container-fluid px-0"><Loading /></div></section>
    }
      
    function handleChangeZoom (newZoom){
        setZoom(newZoom);
    }

    return (
        <section class="banner">
		<div class="container-fluid px-0">
			
			<div class="container">
				<div class="heading">
				    <h1 class="heading-title-left"><i onClick={() => window.history.back()} style={{marginRight:13, cursor:"pointer"}} class="fa fa-long-arrow-left" aria-hidden="true"></i>{chargingStationDetails.title}</h1>
				    {/* <p class="blog-details-date">{chargingStationDetails.sub_title}</p> */}
				</div>

				<div className="row">
                    <img className="col-12" src={Config.cdnUrl + chargingStationDetails.image}/>
                </div>

                <div className="mt-5">
                    {/* <h4 class="title pt-5"></h4> */}
                    <div class="row">
                        <div class="col-lg-6  col-md-12 col-sm-12 col-xs-12">
                            {chargingStationDetails.address &&
                                <div class="mb-4">
                                    <div><strong>Address</strong> <br/> {chargingStationDetails.address}</div>
                                </div>
                            }
                            {chargingStationDetails.contact_number &&
                                <div class="mb-4">
                                    <div><strong>Contact Number</strong> <br/> {chargingStationDetails.contact_number}</div>
                                </div>
                            }
                            {chargingStationDetails.email &&
                                <div class="mb-4">
                                    <div><strong>Email</strong> <br/> {chargingStationDetails.email}</div>
                                </div>
                            }
                            {chargingStationDetails.open_time &&
                                <div class="mb-4">
                                    <div><strong>Working Hours</strong> <br/> {chargingStationDetails.open_time}{' - '}{chargingStationDetails.close_time}</div>
                                </div>
                            }

                            {chargingStationDetails.type_one_ac_charger &&
                                <div class="mb-4">
                                    <div><strong>Type one AC charger</strong> <br/> {chargingStationDetails.type_one_ac_charger}{' ('}{chargingStationDetails.type_one_ac_charger_count}{' Noms)'}</div>
                                </div>
                            }
                            {chargingStationDetails.type_two_charger &&
                                <div class="mb-4">
                                    <div><strong>Type two charger</strong> <br/> {chargingStationDetails.type_two_charger}{' ('}{chargingStationDetails.type_two_charger_count}{' Nos)'}</div>
                                </div>
                            }
                            {chargingStationDetails.bharat_charger_ac &&
                                <div class="mb-4">
                                    <div><strong>Bharat charger AC</strong> <br/> {chargingStationDetails.bharat_charger_ac}{' ('}{chargingStationDetails.bharat_charger_ac_count}{' Nos)'}</div>
                                </div>
                            }
                            {chargingStationDetails.bharat_charger_dc &&
                                <div class="mb-4">
                                    <div><strong>Bharat charger DC</strong> <br/> {chargingStationDetails.bharat_charger_dc}{' ('}{chargingStationDetails.bharat_charger_dc_count}{' Nos)'}</div>
                                </div>
                            }
                            {chargingStationDetails.ccs &&
                                <div class="mb-4">
                                    <div><strong>CCS</strong> <br/> {chargingStationDetails.ccs}{' ('}{chargingStationDetails.ccs_count}{' Nos)'}</div>
                                </div>
                            }
                            {chargingStationDetails.chademo &&
                                <div class="mb-4">
                                    <div><strong>CHAdeMO</strong> <br/> {chargingStationDetails.chademo}{' ('}{chargingStationDetails.chademo_count}{' Nos)'}</div>
                                </div>
                            }
                        </div>


                        <div class="col-lg-6  col-md-12 col-sm-12 col-xs-12">

                            <MapPicker defaultLocation={{lat: parseFloat(chargingStationDetails.location.latitude), lng: parseFloat(chargingStationDetails.location.longitude)}}
                                zoom={zoom}
                                mapTypeId="roadmap"
                                style={{height:'350px'}}
                                // onChangeLocation={handleChangeLocation} 
                                onChangeZoom={handleChangeZoom}
                                apiKey='AIzaSyBb-Vrual4heLKsxWGlXh-hQwCpVjAGmIA'
                            />
  
                        </div>
                    </div>
                </div>
			</div>

		</div>
        </section>
        )

}

export default ChargingStationDetails;