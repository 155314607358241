import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ClearUserRegister, LogoutUser } from '../../../actions/user';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Space } from 'antd';

import ProfileModalContent from './pageContents';

import ForgotPassword from './forgotPassword';
import Register from './register';
import Login from './login';
import Utils from '../../../utils';

function Profile(props) {
    const { Option } = Select;
	const dispatch = useDispatch();
	const [showLogin, setshowLogin] = useState(false)
	var [pageName, setpageName] = useState('LOGIN')
	const user = useSelector(store => store.user);

	const getTitle = () => {
		if(pageName === 'FORGOT_PSWD') {
			return 'Forgot Password?' 
		} else if(pageName === 'REGISTER') {
			return 'Sign up to Evhorse'
		} else {
			return "Login to EVHorse"
		}
	}
	const closeDrawer = () => {
		setshowLogin(false);
		setpageName('LOGIN');
		dispatch(ClearUserRegister())

	}

	const logout = () => {
		dispatch(LogoutUser())
	}

    return (
            <>
            <span  className="float-right pointer mt-1 ml-2" onClick={() => setshowLogin(true)} ><i style={{fontSize: 40}} className="fa fa-user-circle-o  pointer" aria-hidden="true"></i></span>
            
			{user.isCheckLoginComplete === 'FULFILLED' ?
			<Drawer
				title="Profile"
				width={"inherit"}
				onClose={() => closeDrawer()}
				visible={showLogin}
				bodyStyle={{ paddingBottom: 25 }}
				className="login-drawer"
			>	
				<div>

					{/* <div><h4>Hi {user.checkLoginResp.first_name} ,  Welcome to EVHorse</h4></div> */}
					<div className=""  > <a onClick={() => logout()} >  { user.isLogoutComplete === 'PENDING' && Utils.loader()} Logout</a></div>
				</div>
			</Drawer>
			:
			<Drawer
				title={getTitle()}
				width={"inherit"}
				onClose={() => closeDrawer()}
				visible={showLogin}
				bodyStyle={{ paddingBottom: 25 }}
				className="login-drawer"
			>	

				<ProfileModalContent />
        
        	</Drawer>
			}
        </>
    )

}

export default Profile;

