export default {
    // AUTH
    CHECK_LOGIN: 'CHECK_LOGIN',
    USER_LOGIN: 'USER_LOGIN',
    USER_REGISTER: 'USER_REGISTER',
    CLEAR_USER_REGISTER: 'CLEAR_USER_REGISTER',
    RESEND_OPT: 'RESEND_OPT',
    CLEAR_RESEND_OPT: 'CLEAR_RESEND_OPT',
    VERIFY_OPT: 'VERIFY_OPT',
    BOOK_TEST_RIDE: 'BOOK_TEST_RIDE',
    CLEAR_BOOK_TEST_RIDE: 'CLEAR_BOOK_TEST_RIDE',
    LOGOUT_USER: 'LOGOUT_USER',


    FETCH_HOME_LIST: 'FETCH_HOME_LIST',
    FETCH_CAR_LIST: 'FETCH_CAR_LIST',
    FETCH_TRUCK_LIST: 'FETCH_TRUCK_LIST',
    FETCH_MAKE_LIST: 'FETCH_MAKE_LIST',
    FETCH_SINGLE_VEHICLE_DETAILS: 'FETCH_SINGLE_VEHICLE_DETAILS',
    FETCH_EV_NEWS: 'FETCH_EV_NEWS',
    FETCH_SHOWROOMS: 'FETCH_SHOWROOMS',
    FETCH_LOCATIONS: 'FETCH_LOCATIONS',
    FETCH_EV_VIDEOS: 'FETCH_EV_VIDEOS',
    FETCH_CHARGING_STATIONS: 'FETCH_CHARGING_STATIONS',
    FETCH_BIKE_LIST: 'FETCH_BIKE_LIST',
    FETCH_AUTO_LIST: 'FETCH_AUTO_LIST',
    FETCH_SHOWROOM_DETAILS: 'FETCH_SHOWROOM_DETAILS',
    FETCH_CHARGING_STATION_DETAILS: 'FETCH_CHARGING_STATION_DETAILS',
    FETCH_BRAND_VEHICLE_LIST: 'FETCH_BRAND_VEHICLE_LIST',
}