import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Select } from 'antd';
import axios from 'axios';
import Config from '../../ev-config';
import { FetchLocations } from '../../actions/vehicles';
import Utils from '../../utils';
const { Option } = Select;


function Location () {
    const [showModal, setshowModal] = useState(false)
    const [locationChanged, setLocationChanged] = useState(false)

    const dispatch = useDispatch();
    const locations = useSelector(store => store.vehicles.locations);

    useEffect(() => {
        var location = Utils.getLocationObj();
        if(location) {
            locations.push(location)
        } else {
            axios.get(`${Config.apiRoot}/get/city`).then((resp)=> {
                localStorage.setItem("EV-LOCATION", JSON.stringify(resp.data));
                locations.push(resp.data)
            })
        }
    }, []);


    const handleOk = () => {
        setshowModal(false)
    }

    function onChange(value, obj) {
        localStorage.setItem("EV-LOCATION", JSON.stringify(obj.props));
        setLocationChanged(true)
    }
    
    function onSearch(value) {
        dispatch(FetchLocations(value))
    }
    function afterClose() {
        if(locationChanged) {
            window.location.reload()
            setLocationChanged(false)
        }
    }
   
    return (
        <>
            <span title="Set your current location" className="float-right pointer mt-1 ml-2" onClick={() => setshowModal(true)}  ><i style={{fontSize: 40}} className="fa fa-map-marker  pointer" aria-hidden="true"></i></span>
            <Modal
                title="Select your location"
                visible={showModal}
                onOk={handleOk}
                onCancel={() => setshowModal(false)}
                okText="Continue"
                cancelButtonProps={{disabled: true, className: 'd-none'}}
                afterClose={() => afterClose()}
            >
                <div>
                
                    <Select
                        size="large"
                        showSearch
                        style={{ width: "100%"}}
                        placeholder="Please select your location"
                        optionFilterProp="children"
                        defaultValue={locations.length ? locations[0].pincode : null}
                        onChange={onChange}
                        onSearch={onSearch}
                    >
                        {
                            locations.map((l) => {
                                return <Option props={l} value={l.pincode}>{l.name}{' ('}{l.district}{')'}</Option>
                            })
                        }
                    </Select>

                </div>
            </Modal>
        </>
    )
}

export default Location;