import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import _ from "lodash";
import ImageGallery from 'react-image-gallery';
import { FetchVehicleDetails } from '../actions/vehicles';
import Config from '../ev-config';
import Loading from '../components/loading';

import Showrooms from './category/showrooms';
import ChargingStations from './category/chargingStations';
import Varients from './category/varients';
import News from './category/news';

function VehicleDetails(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const dispatch = useDispatch();
    let { carId } = useParams();
    const vehicleDetails = useSelector(store => store.vehicles.vehicleDetails);

    useEffect(() => {
        dispatch(FetchVehicleDetails(carId))
    }, [carId])


    if(!vehicleDetails) {
        return <section class="banner"><div class="container-fluid px-0"><Loading /></div></section>
    }

    var images = []
    vehicleDetails.preview_images.map((img) => {
        images.push({
            original: Config.cdnUrl + img,
            thumbnail: Config.cdnUrl + img,
        })
    })

    return (
        <section class="banner">
		<div class="container-fluid px-0">
			
			<div class="container mb-4">
				<div class="heading">
				    <h1 class="heading-title-left"><i onClick={() => window.history.back()} style={{marginRight:13, cursor:"pointer"}} class="fa fa-long-arrow-left" aria-hidden="true"></i>{vehicleDetails.title}</h1>
				    <p class="blog-details-date">{vehicleDetails.sub_title}</p>
				</div>
				<div class="blog-detail-main-img">
                    <ImageGallery items={images} />
                </div>

                <div className="mt-5">
                    {vehicleDetails.description}
                </div>

                <div className="mt-5">
                    <h4 class="title pt-5">Specifications & Features</h4>
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Acceleration</strong> <br/>{vehicleDetails.acceleration}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Battery</strong> <br/>{vehicleDetails.battery}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Top Speed</strong> <br/>{vehicleDetails.topspeed}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Real Time Rage</strong> <br/>{vehicleDetails.realtime_range}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>ARAI Range </strong> <br/>{vehicleDetails.arai_test_range}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Efficiency</strong> <br/>{vehicleDetails.efficiency}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Normal Charge Speed</strong> <br/>{vehicleDetails.normalcharge_speed}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Fast Charge Speed</strong> <br/>{vehicleDetails.fastcharge_speed}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Number Of Seats</strong> <br/>{vehicleDetails.no_of_seats}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Electric Motor Type</strong> <br/>{vehicleDetails.electric_motor_type}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Electric Motor Power</strong> <br/>{vehicleDetails.electric_motor_power}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Electric Motor Torque</strong> <br/>{vehicleDetails.electric_motor_torque}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>BHP</strong> <br/>{vehicleDetails.bhp}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Transmission</strong> <br/>{vehicleDetails.transmission}</div>
                        {/* <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Dimensions</strong> <br/>{vehicleDetails.dimensions}</div> */}
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Suspension Front</strong> <br/>{vehicleDetails.suspension_front}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Suspension Rear</strong> <br/>{vehicleDetails.suspension_rear}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Breaks Front</strong> <br/>{vehicleDetails.brakes_front}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Breaks Rear</strong> <br/>{vehicleDetails.brakes_rear}</div>
                        <div class="col-md-4 col-sm-6 col-xs-12 mt-4"><strong>Tyre</strong> <br/>{vehicleDetails.tyre}</div>

                       
                    </div>
                </div>
			</div>

            <Showrooms fullWidth={true} showrooms={vehicleDetails.showrooms} apiFetch={false} type=""  />
            <ChargingStations fullWidth={true} chargingStations={vehicleDetails.charging_stations} apiFetch={false} />
            {typeof vehicleDetails.variants !== 'undefined' &&
            <Varients fullWidth={true} variants={vehicleDetails.variants} />
            }
            <News fullWidth={true} evNews={vehicleDetails.news} apiFetch={false} type="" />


		</div>
	</section>
    )

}

export default VehicleDetails;