import React from "react";
import {
    BrowserRouter as Router,
    useHistory,
} from "react-router-dom";

export default function Footer() {
    const history = useHistory();


    return (
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-5 col-sm-12 col-xs-12">
                        <div class="footer-logo">
                            <img src="/assets/images/cropped-evhorse-logo-1-1.png"/>
                            <p class="footer-text">Plug-in to the future. Following the transportation industry with Electric vehicles,Solar,Hydrogen Fuel Cells Sector</p>
                        </div>
                    </div>
                    
                  
                    <div class="col-md-4 col-sm-12 col-xs-12 mt-md-2">
                        <div class="footer-menu">
                            <div class="footer-menu-item" onClick={() => history.push('/privacy-policy')} ><span><i class="fa fa-circle" aria-hidden="true"></i></span><span>Privacy Policy</span></div>
                            <div class="footer-menu-item" onClick={() => history.push('/terms-and-conditions')} ><span><i class="fa fa-circle" aria-hidden="true"></i></span><span>Terms And Conditions</span></div>
                            <div class="footer-menu-item" onClick={() => window.open('https://blog.evhorse.com/contact-us/')} ><span><i class="fa fa-circle" aria-hidden="true"></i></span><span>Contact</span></div>
                            <div class="footer-menu-item" onClick={() => window.open('https://blog.evhorse.com/advertise-with-us/')} ><span><i class="fa fa-circle" aria-hidden="true"></i></span><span>Advertise</span></div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12 mt-md-2">
                        <div class="footer-menu">
                            {/* <div class="footer-menu-item" onClick={() => history.push('/privacy-policy')} ><span><i class="fa fa-circle" aria-hidden="true"></i></span><span>Privacy Policy</span></div> */}
                            <div class="col-12"><div onClick={() => window.open('https://www.facebook.com/EvHorse-100331392369109/')} class="footer-menu-item"><span ><i class="fa fa-facebook-square" aria-hidden="true"></i></span><span>Facebook</span></div></div>
                            <div class="col-12"><div onClick={() => window.open("https://twitter.com/evhorse1")} class="footer-menu-item"><span><i class="fa fa-twitter-square" aria-hidden="true"></i></span><span>Twitter</span></div></div>
                            <div class="col-12"><div onClick={() => window.open("https://www.youtube.com/channel/UC68w61lDyEyRfI56YVy2SuQ")} class="footer-menu-item"><span><i class="fa fa-youtube-square" aria-hidden="true"></i></span><span>Youtube</span></div></div>
                            <div class="col-12"><div onClick={() => window.open("https://www.instagram.com/ev_horse/")} class="footer-menu-item"><span><i class="fa fa-instagram" aria-hidden="true"></i></span><span>Instagram</span></div></div>
                        </div>
                    </div>
                    {/* <div class="col-md-4 col-sm-12 col-xs-12 mt-md-5 footer-social-icons">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12"><div onClick={() => window.open('https://www.facebook.com/EvHorse-100455102051007')} class="footer-menu-item"><span ><i class="fa fa-facebook" aria-hidden="true"></i></span><span>Facebook</span></div></div>
                            <div class="col-md-6 col-sm-12 col-xs-12"><div onClick={() => window.open("https://twitter.com/evhorse1")} class="footer-menu-item"><span><i class="fa fa-twitter" aria-hidden="true"></i></span><span>Twitter</span></div></div>
                        </div>
                    </div> */}
                </div>
                <div style={{fontSize: 'large'}} className="font-italic text-center white mt-4" >
                    All rights reserved @ RAASTA INFOTECH LLP
                </div>
            </div>
        </footer>
    )
}