import events from '../../actions/event-names';
import Config from '../../ev-config';


const initialState = {
    isFetchHomeListComplete: false,
    isFetchCarListComplete: false,
    isFetchTruckListComplete: false,
    isFetchMakeListComplete: false,
    isFetchAutoListComplete: false,
    makes: [],
    cars: [],
    trucks: [],
    vehicleDetails: '',
    evNews: [],
    showrooms: [],
    locations: [],
    evVideos: [],
    chargingStations: [],
    bikes: [],
    autos: [],
    showroomDetails: "",
    chargingStationDetails: "",
    brandVehicleList: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    // HOME LIST
    case `${events.FETCH_HOME_LIST}_PENDING`: {
        return {
            ...state,
            isFetchHomeListComplete: 'PENDING',
        };
    }
    case `${events.FETCH_HOME_LIST}_FULFILLED`: {
        return {
            ...state,
            isFetchHomeListComplete: 'FULFILLED',
            cars: [...action.payload.data.cars],
            bikes: [...action.payload.data.bikes],
            autos: [...action.payload.data.autos],
        };
    }
    case `${events.FETCH_HOME_LIST}_REJECTED`: {
        return {
            ...state,
            isFetchHomeListComplete: 'REJECTED',
        };
    }

     //MAKE LIST
     case `${events.FETCH_MAKE_LIST}_PENDING`: {
        return {
            ...state,
            isFetchMakeListComplete: 'PENDING',
            makes: [],
        };
    }
    case `${events.FETCH_MAKE_LIST}_FULFILLED`: {
        return {
            ...state,
            isFetchMakeListComplete: 'FULFILLED',
            makes: action.payload.data,
        };
    }
    case `${events.FETCH_MAKE_LIST}_REJECTED`: {
        return {
            ...state,
            isFetchMakeListComplete: 'REJECTED',
        };
    }

    //CAR LIST
    case `${events.FETCH_CAR_LIST}_PENDING`: {
        return {
            ...state,
            isFetchCarListComplete: 'PENDING',
        };
    }
    case `${events.FETCH_CAR_LIST}_FULFILLED`: {
        return {
            ...state,
            isFetchCarListComplete: 'FULFILLED',
            cars: action.payload.data,
        };
    }
    case `${events.FETCH_CAR_LIST}_REJECTED`: {
        return {
            ...state,
            isFetchCarListComplete: 'REJECTED',
        };
    }

    //CAR LIST
    case `${events.FETCH_AUTO_LIST}_PENDING`: {
        return {
            ...state,
            isFetchAutoListComplete: 'PENDING',
        };
    }
    case `${events.FETCH_AUTO_LIST}_FULFILLED`: {
        return {
            ...state,
            isFetchAutoListComplete: 'FULFILLED',
            autos: action.payload.data,
        };
    }
    case `${events.FETCH_AUTO_LIST}_REJECTED`: {
        return {
            ...state,
            isFetchAutoListComplete: 'REJECTED',
        };
    }

     //BIKE LIST
     case `${events.FETCH_BIKE_LIST}_PENDING`: {
        return {
            ...state,
            isFetchBikeListComplete: 'PENDING',
        };
    }
    case `${events.FETCH_BIKE_LIST}_FULFILLED`: {
        return {
            ...state,
            isFetchBikeListComplete: 'FULFILLED',
            bikes: action.payload.data,
        };
    }
    case `${events.FETCH_BIKE_LIST}_REJECTED`: {
        return {
            ...state,
            isFetchBikeListComplete: 'REJECTED',
        };
    }

    //TRUCK LIST
    case `${events.FETCH_TRUCK_LIST}_PENDING`: {
        return {
            ...state,
            isFetchTruckListComplete: 'PENDING',
        };
    }
    case `${events.FETCH_TRUCK_LIST}_FULFILLED`: {
        return {
            ...state,
            isFetchTruckListComplete: 'FULFILLED',
            trucks: action.payload.data,
        };
    }
    case `${events.FETCH_TRUCK_LIST}_REJECTED`: {
        return {
            ...state,
            isFetchTruckListComplete: 'REJECTED',
        };
    }

       //VEHICLE DETAILS
    case `${events.FETCH_SINGLE_VEHICLE_DETAILS}_PENDING`: {
        return {
            ...state,
            vehicleDetails: '',
            isFetchVehicleDetailsComplete: 'PENDING',
        };
    }
    case `${events.FETCH_SINGLE_VEHICLE_DETAILS}_FULFILLED`: {
        return {
            ...state,
            isFetchVehicleDetailsComplete: 'FULFILLED',
            vehicleDetails: action.payload.data,
        };
    }
    case `${events.FETCH_SINGLE_VEHICLE_DETAILS}_REJECTED`: {
        return {
            ...state,
            isFetchVehicleDetailsComplete: 'REJECTED',
        };
    }


         // EV NEWS
    case `${events.FETCH_EV_NEWS}_PENDING`: {
        return {
            ...state,
            evNews: [],
            isEvNewsFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_EV_NEWS}_FULFILLED`: {
        return {
            ...state,
            isEvNewsFetchComplete: 'FULFILLED',
            evNews: action.payload.data,
        };
    }
    case `${events.FETCH_EV_NEWS}_REJECTED`: {
        return {
            ...state,
            isEvNewsFetchComplete: 'REJECTED',
        };
    }


    // SHOWROOMS
    case `${events.FETCH_SHOWROOMS}_PENDING`: {
        return {
            ...state,
            showrooms: [],
            isShowroomsFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_SHOWROOMS}_FULFILLED`: {
        return {
            ...state,
            isShowroomsFetchComplete: 'FULFILLED',
            showrooms: action.payload.data,
        };
    }
    case `${events.FETCH_SHOWROOMS}_REJECTED`: {
        return {
            ...state,
            isShowroomsFetchComplete: 'REJECTED',
        };
    }


    // LOCATIONS
    case `${events.FETCH_LOCATIONS}_PENDING`: {
        return {
            ...state,
            isLocationFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_LOCATIONS}_FULFILLED`: {
        return {
            ...state,
            isLocationFetchComplete: 'FULFILLED',
            locations: action.payload.data,
        };
    }
    case `${events.FETCH_LOCATIONS}_REJECTED`: {
        return {
            ...state,
            isLocationFetchComplete: 'REJECTED',
        };
    }


    // EV NEWS
    case `${events.FETCH_EV_VIDEOS}_PENDING`: {
        return {
            ...state,
            evVideos: [],
            isEvVideosFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_EV_VIDEOS}_FULFILLED`: {
        return {
            ...state,
            isEvVideosFetchComplete: 'FULFILLED',
            evVideos: action.payload.data,
        };
    }
    case `${events.FETCH_EV_VIDEOS}_REJECTED`: {
        return {
            ...state,
            isEvVideosFetchComplete: 'REJECTED',
        };
    }

    // CHARGING STATIONS
    case `${events.FETCH_CHARGING_STATIONS}_PENDING`: {
        return {
            ...state,
            chargingStations: [],
            isChargingStationsFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_CHARGING_STATIONS}_FULFILLED`: {
        return {
            ...state,
            isChargingStationsFetchComplete: 'FULFILLED',
            chargingStations: action.payload.data,
        };
    }
    case `${events.FETCH_CHARGING_STATIONS}_REJECTED`: {
        return {
            ...state,
            isChargingStationsFetchComplete: 'REJECTED',
        };
    }


      // SHOWROOM DETAILS
    case `${events.FETCH_SHOWROOM_DETAILS}_PENDING`: {
        return {
            ...state,
            showroomDetails: "",
            isShowroomDetailsFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_SHOWROOM_DETAILS}_FULFILLED`: {
        return {
            ...state,
            isShowroomDetailsFetchComplete: 'FULFILLED',
            showroomDetails: action.payload.data,
        };
    }
    case `${events.FETCH_SHOWROOM_DETAILS}_REJECTED`: {
        return {
            ...state,
            isShowroomDetailsFetchComplete: 'REJECTED',
        };
    }


    //CHARGING STATION DETAILS
    case `${events.FETCH_CHARGING_STATION_DETAILS}_PENDING`: {
        return {
            ...state,
            chargingStationDetails: "",
            isChargingStationDetailsFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_CHARGING_STATION_DETAILS}_FULFILLED`: {
        return {
            ...state,
            isChargingStationDetailsFetchComplete: 'FULFILLED',
            chargingStationDetails: action.payload.data,
        };
    }
    case `${events.FETCH_CHARGING_STATION_DETAILS}_REJECTED`: {
        return {
            ...state,
            isChargingStationDetailsFetchComplete: 'REJECTED',
        };
    }


    //CHARGING STATION DETAILS
    case `${events.FETCH_BRAND_VEHICLE_LIST}_PENDING`: {
        return {
            ...state,
            brandVehicleList: [],
            isBrandVehiclesFetchComplete: 'PENDING',
        };
    }
    case `${events.FETCH_BRAND_VEHICLE_LIST}_FULFILLED`: {
        return {
            ...state,
            isBrandVehiclesFetchComplete: 'FULFILLED',
            brandVehicleList: action.payload.data,
        };
    }
    case `${events.FETCH_BRAND_VEHICLE_LIST}_REJECTED`: {
        return {
            ...state,
            isBrandVehiclesFetchComplete: 'REJECTED',
        };
    }
    
    

    default:
        return state;
    }
}
