import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { FetchChargingStations } from '../../actions/vehicles';
import {
    Link,
    useHistory
} from "react-router-dom";
import Config from '../../ev-config';

function  ChargingStations(props) {
    const history = useHistory();
    const dispatch = useDispatch();
  
    var chargingStations = useSelector(store => store.vehicles.chargingStations);
    const [index, setIndex] = useState(0)

    useEffect(() => {
        if(props.apiFetch !== false) {
            dispatch(FetchChargingStations())
        }
    }, []);

    if(props.apiFetch === false) {
        chargingStations = props.chargingStations
    }

    if(chargingStations.length === 0) {
        return <div></div>
    }

    return (
        <div class="up-events">
            <div class={`${props.fullWidth === true ? 'container-fluid' : 'container'}`}>
                <div className="row py-3" >
                    <div class="col-12">
                        <h4 class="title">Charging Stations</h4>
                            <div class="row position-relative ">
                            {index+4 < chargingStations.length &&
                            <i onClick={() => setIndex(index + 1)} class="fa fa-chevron-circle-right blox-next-icon pointer" aria-hidden="true"></i>
                            }
                            {index > 0 &&
                            <i onClick={() => setIndex(index - 1)} class="fa fa-chevron-circle-left blox-previous-icon pointer" aria-hidden="true"></i>
                            }
                            {getNews()} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function getNews() {
        var list = []
        for (var i=index; i<index+4 && i < chargingStations.length; i++) {
            if(chargingStations.length) {
                const a = i
                list.push(
                    <article onClick={() => history.push('/charging-stations/' + chargingStations[a].uuid)}   className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pointer" id={chargingStations[a].title} >
                        <div className="blog mt-3">
                            <div className="blog-img">
                                <img alt="loading" src={Config.cdnUrl + chargingStations[a].image}/>
                            </div >
                            <div class="blog-desc">
                                <div class="blog-title white">{chargingStations[a].title}</div>
                                <p class="white blog-description">{chargingStations[a].location.name} {', '}{chargingStations[a].location.district}</p>
                            </div>
                        </div>
                    </article>
                )
            }
        }
        return list;
    }
}

export default ChargingStations;