import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { FetchEvVideos } from '../../actions/vehicles';
import {
    Link,
    useHistory
} from "react-router-dom";

function  Videos(props) {
    const history = useHistory();
    const dispatch = useDispatch();
  
    const evVideos = useSelector(store => store.vehicles.evVideos);
    const [index, setIndex] = useState(0)

    useEffect(() => {
        // dispatch(FetchEvVideos(props.type))
    }, []);


    if(evVideos.length === 0) {
        return <div></div>
    }

    return (
        <div class="up-events">
            <div class={`${props.fullWidth === true ? 'container-fluid' : 'container'}`}>
                <div className="row py-3" >
                    <div class="col-12">
                        <h4 class="title">Latest EV Videos</h4>
                        <div class="row position-relative ">
                            {index+4 < evVideos.length &&
                            <i onClick={() => setIndex(index + 1)} class="fa fa-chevron-circle-right blox-next-icon pointer" aria-hidden="true"></i>
                            }
                            {index > 0 &&
                            <i onClick={() => setIndex(index - 1)} class="fa fa-chevron-circle-left blox-previous-icon pointer" aria-hidden="true"></i>
                            }
                            {getVideos()} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function getVideos() {
        var list = []
        for (var i=index; i<index+4 && i < evVideos.length; i++) {
            if(evVideos.length) {
                const a = i
                list.push(
                    <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pointer" id={evVideos[a].title}  href={evVideos[a].link} onClick={() => window.open(evVideos[a].link,'_blank')}  >
                        <div className="blog mt-3">
                            <div className="blog-img">
                                <img alt="loading" className="category-img" src={evVideos[a].image}/>
                            </div >
                            <div class="blog-desc">
                                <div class="blog-title white">{evVideos[a].title}</div>
                                </div>
                        </div>
                    </article>
                )
            }
        }
        return list;
    }
}

export default Videos;