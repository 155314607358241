import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Select } from 'antd';
import Config from '../ev-config';
import Loading from '../components/loading';
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"
import _ from 'lodash'
import FindYourChargingStationMarker from './findYourChargingStationMarker';
const { Option } = Select;

function FindYourChargingStation(props) {
    const [vehicles, setVehicles] = useState([]);
    const [chargingStations, setChargingStations] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(`${Config.apiRoot}/vehicles?search=a`).then((resp)=> {
            setVehicles(resp.data)
        })
        setLoading(true)
        axios.get(`${Config.apiRoot}/chargingstations`).then((resp)=> {
            var points = []
            resp.data.map((s, i) => {
                if(s.location && s.location.latitude && s.location.longitude) {
                    s.showInfo = false;
                    points.push(s)
                }
            })
            setChargingStations(points)
            setLoading(false)
        })
    }, [])

    function onChange(value, obj) {
        setLoading(true)
        axios.get(`${Config.apiRoot}/vehicles/${value}`).then((resp)=> {
            var points = []
            resp.data.charging_stations.map((s, i) => {
                if(s.location && s.location.latitude && s.location.longitude) {
                    s.showInfo = false;
                    points.push(s)
                }
            })
            setChargingStations(points)
            setLoading(false)
        })
        
    }
    
    function onSearch(value) {
        if(value) {
            axios.get(`${Config.apiRoot}/vehicles?search=${value}`).then((resp)=> {
                setVehicles(resp.data)
            })
        }
    }


    var MapWithAMarker = ""
    if(chargingStations.length) {
        MapWithAMarker = withScriptjs(withGoogleMap(props =>
            <GoogleMap
                defaultZoom={4}
                defaultCenter={{ lat: parseFloat(chargingStations[0].location.latitude), lng: parseFloat(chargingStations[0].location.longitude)}}
            >
                <FindYourChargingStationMarker chargingStations={chargingStations} />
            </GoogleMap>
        ));
    }


    return (
        <section class="banner">
		<div class="container-fluid px-0">
			
			<div style={{marginTop: 50}} class="container">
				<div className="text-center col-12"  >
                    <Select
                        size="large"
                        showSearch
                        className="col-10"
                        placeholder="Please select your vehicle to find the chargin station"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        suffixIcon={<i class="fa fa-search"></i>}
                    >
                    {
                        vehicles.map((o) => {
                            return <Option props={o} value={o.uuid}>{o.title}{' ('}{o.make}{')'}</Option>
                        })
                    }
                    </Select>
				</div>



				
                <div className="mt-5">
                    <div class="row">
                        <div style={{height:500}} class="col-12">
                            {loading &&
                            <Loading />
                            }           
                            {!loading && chargingStations.length > 0 &&
                            <MapWithAMarker
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBb-Vrual4heLKsxWGlXh-hQwCpVjAGmIA&region=IN&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `500%` }} />}
                                containerElement={<div style={{ height: `500px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                            }
                        </div>
                    </div>
                </div>
			</div>

		</div>
        </section>
        )

}

export default FindYourChargingStation;