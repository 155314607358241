import { message } from 'antd';

function getLocationObj() {
    var location = localStorage.getItem('EV-LOCATION');
    if(location) {
        location = JSON.parse(location)
    }
    return location;
}

function getLocationCode() {
    var location = localStorage.getItem('EV-LOCATION');
    if(location) {
        return JSON.parse(location).pincode
    }
    return "";
}

var showSuccess = (msg) => {
    message.success(msg);
}

var showInfo = (msg) => {
    message.info(msg);
}

var showError = (msg) => {
    message.error(msg);
}

var loader = () => {
    return <i class="fa fa-spinner fa-spin"></i>
}

export default {
    getLocationObj,
    getLocationCode,
    showError,
    showSuccess,
    showInfo,
    loader,
}