import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/loading';
import {
    Link,
    useHistory
} from "react-router-dom";
import _ from 'lodash';
import { FetchHomeList, FetchMakeList } from '../actions/vehicles';
import UpcomingActivities from './category/upcomingActivities';
import News from './category/news';
import Showrooms from './category/showrooms';
import Videos from './category/videos';
import ChargingStations from './category/chargingStations';
import MakeJson from '../dataFiles/make.json';
import { Spin, Space } from 'antd';
import Dropdown from '../components/menu/dropDown';
import Config from '../ev-config';
import TestRide from './functions/testRide';


function Home(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [selMakes, setSelMakes] = useState([])
    const [selBodyStyles, setSelBodyStyles] = useState([])
    const [selAvailability, setSelAvailability] = useState([])
    const [datas, setDatas] = useState()
    const [showModal, setshowModal] = useState(false)
    const [selvehicle, setSelVehicle] = useState("")
    const vehicle = useSelector(store => store.vehicles);

    
    useEffect(() => {
        dispatch(FetchHomeList(""))
        dispatch(FetchMakeList(""))
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    function makeSelectChange(sm){
        var makeList = []
        sm.map((o) => {
            makeList.push(o.label)
        })
        setSelMakes(makeList)
        setPage(1)
    };
    function bodyStyleSelectChange(sm){
        var bsList = []
        sm.map((o) => {
            bsList.push(o.label)
        })
        setSelBodyStyles(bsList)
        setPage(1)
    };
    function availabilitySelectChange(sm){
        var avList = []
        sm.map((o) => {
            avList.push(o.value)
        })
        setSelAvailability(avList)
        setPage(1)
    };
    function triggerBrandClick(brand) {
        var makeList = []
        setSelMakes([brand.name])
        setPage(1)
        window.scrollTo(0, 0)
    }
    const triggerTestRide = (data) => {
        setSelVehicle(data.uuid);
        setshowModal(true);
    }

    return (
        <>
        <div class="container-fluid px-0">
            <div class="banner-bg">
                <div class="heading">
                    <h1 class="heading-title"><span>Plug-in</span> to the future</h1>
                </div>
            </div>
        </div>
        <section class="main">

        {vehicle.isFetchHomeListComplete !== 'FULFILLED' &&
            <Loading />
        }

        <div class="container-fluid seperator-bottom">  
            {vehicle.cars.length && 
                <div class="py-3">
                    <h4 class="title">Top Brands</h4>
                    {/* <p class="sub-title">What are the world’s most popular electric car brands?Tesla topped the world as the best-selling electric vehicle brand</p> */}
                                    
                    <div class="col row brands mt-3">
                        {
                            _.sortBy(vehicle.makes, [function(o) { return o.title; }]).map((o, i) => {
                                if(o.image) {
                                    return (<div key={o.image}  className="mr-3 mb-3">
                                            <Dropdown type="home" data={o} />
                                        </div>)
                                }
                            
                            })
                        }
                    </div>
                </div>
            }


            {vehicle.bikes.length &&
                <div class="row py-3">
                    <div class="col-12">
                        <h4 class="title">Two Wheelers</h4>
                        <p class="sub-title">Below are some of the <em>electric bikes and scooters</em> avaliable in the Indian market</p>
                        <div className="row" >
                            {getArticle(vehicle.bikes)}  
                        </div>
                        <Link to="/bikes"><div className="view-more" >View More</div></Link>
                    </div>

                    
                </div>
            }

            {vehicle.autos.length &&
                <div class="row py-3">
                    <div class="col-12">
                        <h4 class="title">Autos</h4>
                        <p class="sub-title">Below are some of the <em>electric autos</em> avaliable in the Indian market</p>
                        <div className="row" >
                            {getArticle(vehicle.autos)}  
                        </div>
                        <Link to="/autos"><div className="view-more" >View More</div></Link>
                    </div>
                </div>
            }

            {vehicle.cars.length &&
                <div class="row py-3">
                    <div class="col-12">
                        <h4 class="title">Electric Cars</h4>
                        <p class="sub-title">Below are some of the <em>electric cars</em> avaliable in the Indian market</p>
                        <div className="row" >
                            {getArticle(vehicle.cars)}  
                        </div>
                        {/* {vehicle.cars.length &&
                        <div class="pagination">
                            {loadPagination()}
                        </div>
                        } */}
                        <Link to="/cars"><div className="view-more" >View More</div></Link>
                    </div>
                </div>
            }
        </div>
              
        
       
        
        <Showrooms fullWidth={true} type=""  />
        <Videos fullWidth={true} type=""  />
        <ChargingStations fullWidth={true} />
        <News fullWidth={true} type="" />
         <div class="col-12 pb-5">
            <a href="https://blog.evhorse.com" target="_blank" ><div className="view-more" >View More</div></a>
        </div>

        </section>

        {selvehicle &&
            <TestRide selvehicle={selvehicle} showModal={showModal} setshowModal={setshowModal}  />
        }

        </>
    )


    function loadNextPage(p) {
        setPage(p)
        window.scrollTo(0, 0)
    }
                        
    function loadPagination() {
        var length = parseInt((vehicle.cars.length / 10).toFixed())
        if(length <= 0 ) length = 1
        return Array.from(Array(length), (e, i) => {
            return (<span class={`${i+1 === page ? 'active' : ''}`}  onClick={() => loadNextPage(i+1)}  >{i + 1}</span>)
        })
    }

    function getConceptText(data) {
        if(data.is_upcoming) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Upcoming</span>
        } else if(data.is_concept) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Concept</span>
        } 
        
    }

  
    
    function getArticle(vh) {
        return vh.map((data, i) => {
            return (
                <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={data.uuid} >
                    <div class="blog mt-3">
                        <div class="blog-img">
                            <Link to={`/${data.vehicle_type}s/${data.make}/${data.title}/${data.uuid}/details`}><img src={Config.cdnUrl + data.thumbnail}/></Link>
                        </div>
                        <div class="blog-desc">
                            <div class="blog-title"><a href={`/${data.vehicle_type}s/${data.make}/${data.title}/${data.uuid}/details`}>{data.title}</a> {getConceptText(data)}</div>
                            <p class="blog-date">{data.sub_title}</p>
                            {data.test_ride &&
                            <div className="text-right" >  
                                <button onClick={() => triggerTestRide(data)} className="btn btn-sm" style={{borderRadius: 15}} ><b>Test Ride</b></button>
                            </div>
                            }


                            {/* <p class="white blog-description">{data.specifications.model}</p> */}
                            {/* <table>
                                <tr>
                                    <td>0 - 100</td><td>Top Speed</td><td> Range</td><td>Efficiency</td><td>Fastcharge</td>
                                </tr>
                                <tr>
                                    <td>{data.specifications.acceleration}</td><td>{data.specifications.topspeed}</td><td>{data.specifications.erange_real}</td><td>{data.specifications.efficiency}</td><td>{data.specifications.fastcharge_speed}</td>
                                </tr>
                            </table> */}
                            
                        </div>
                    </div>
                </article>
            )
        })
    }
}

export default Home;