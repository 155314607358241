import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import Vehicles from './vehicles';
import User from './user';


export default combineReducers({
    router: routerReducer,
    vehicles: Vehicles,
    user: User,

});
