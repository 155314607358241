import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../ev-config';

import { FetchEvNews } from '../../actions/vehicles';
import {
    Link,
    useHistory
} from "react-router-dom";

function  Variants(props) {
    const history = useHistory();
    const dispatch = useDispatch();
  
    const variants  = props.variants
    const [index, setIndex] = useState(0)
    if(variants.length === 0) {
        return <div></div>
    }

    return (
        <div class="up-events">
            <div class={`${props.fullWidth === true ? 'container-fluid' : 'container'}`}>
                <div className="row py-3" >
                    <div class="col-12">
                        <h4 class="title">Variants</h4>
                        <div class="row position-relative ">
                            {index+4 < variants.length &&
                            <i onClick={() => setIndex(index + 1)} class="fa fa-chevron-circle-right blox-next-icon pointer" aria-hidden="true"></i>
                            }
                            {index > 0 &&
                            <i onClick={() => setIndex(index - 1)} class="fa fa-chevron-circle-left blox-previous-icon pointer" aria-hidden="true"></i>
                            }
                            {getNews()} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function getNews() {
        var list = []
        for (var i=index; i<index+4 && i < variants.length; i++) {
            if(variants.length) {
                const a = i
                list.push(
                    <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pointer" id={variants[a].title} href={variants[a].link} onClick={() => history.push(`/cars/${variants[a].make}/${variants[a].uuid}/details`)}  >
                        <div className="blog mt-3">
                            <div className="blog-img">
                                <img alt="loading" className="category-img" src={Config.cdnUrl + variants[a].thumbnail}/>
                            </div >
                            <div class="blog-desc">
                                <div class="blog-title white">{variants[a].title}</div>
                            </div>
                        </div>
                    </article>
                )
            }
        }
        return list;
    }
}

export default Variants;