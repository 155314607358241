import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    useParams,
    useHistory,
} from "react-router-dom";
import _ from 'lodash';
import { FetchAutoList, FetchMakeList } from '../actions/vehicles';
import Config from '../ev-config';
import News from './category/news';
import Showrooms from './category/showrooms';
import Videos from './category/videos';
import ChargingStations from './category/chargingStations';

var filterData = [];

function AutoList(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let { brand } = useParams();
    const history = useHistory();
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [selMakes, setSelMakes] = useState([])
    const vehicle = useSelector(store => store.vehicles);
    

    useEffect(() => {
        dispatch(FetchAutoList())
        dispatch(FetchMakeList('auto'))
        if(typeof brand !== 'undefined') {
            setSelMakes([brand])
        }
    }, [])
    useEffect(() => {
        if(history.location.pathname === '/autos') {
            setSelMakes([])
        }
    }, [history.location.pathname])
  
    return (
        <section class="main">
            <div class="container-fluid seperator-bottom">
               
                <div class="py-3">
                    <h4 class="title">Top EV Brands</h4>
                    <div class="col row brands mt-3">
                        {
                            _.sortBy(vehicle.makes, [function(o) { return o.name; }]).map((o, i) => {
                            
                                return <div key={o.image} class="mr-3 mb-3">
                                            <img onClick={() => triggerBrandClick(o)} src={Config.cdnUrl + o.image} class="img-fluid" />
                                        </div>
                                
                            })
                        }
                    </div>
                </div>



                <div class="row py-3">
                    <div class="col-12">
                        <h4 class="title">Electric Autos</h4>
                        <div className="row" >
                            {getArticle()}  
                        </div>
                        {vehicle.bikes.length &&
                        <div class="pagination">
                            {loadPagination()}
                        </div>
                        }
                    </div>
                </div>
            </div>
                
            
            <Showrooms fullWidth={true} type="auto"  />
            <Videos fullWidth={true} type="auto"  />
            <ChargingStations fullWidth={true} />
            <News fullWidth={true} type="auto" />
        </section>
    )

  
    function triggerBrandClick(brand) { 
        history.push('/' + 'brand/' + brand.title)
        // setSelMakes([brand.title])
        // setPage(1)
        // window.scrollTo(0, 0)
    }


    function loadNextPage(p) {
        setPage(p)
        window.scrollTo(0, 0)
    }
                        
    function loadPagination() {
        if(filterData.length >10) {
            var length = Math.ceil(filterData.length / 10)
            if(length <= 0 ) length = 1
            return Array.from(Array(length), (e, i) => {
                return (<span class={`${i+1 === page ? 'active' : ''}`}  onClick={() => loadNextPage(i+1)}  >{i + 1}</span>)
            })
        }
    }

    function getConceptText(data) {
        if(data.is_upcoming) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Upcoming</span>
        } else if(data.is_concept) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Concept</span>
        } 
        
    }
    
    function getArticle() {
        filterData = vehicle.autos
        if(selMakes.length) filterData =  _.filter(vehicle.autos, function(o) { return selMakes.indexOf(o.make) !== -1; });
       
        if(props.searchkey.length) {
            var newList = []
            var DELIMETER = '~!~';
            filterData.forEach((data) => {
                var combinedStr = data.title + DELIMETER + 
                data.sub_title  + DELIMETER + data.model + 
                DELIMETER + data.make + DELIMETER + data.efficiency;
                if(combinedStr.toLowerCase().indexOf(props.searchkey.toLowerCase()) !== -1) {
                    newList.push(data);
                }
            });
            filterData = newList
        }
        
        return filterData.map((data, i) => {
            return (
                <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12"  key={data.uuid} >
                    <div class="blog mt-3">
                        <div class="blog-img">
                            <Link to={`/autos/${data.make}/${data.title}/${data.uuid}/details`}><img src={Config.cdnUrl + data.thumbnail}/></Link>
                        </div>
                        <div class="blog-desc">
                            <div class="blog-title"><a href={`/autos/${data.make}/${data.title}/${data.id}/details`}>{data.title}</a> {getConceptText(data)}</div>
                            <p class="blog-date">{data.sub_title}</p>
                            {/* <p class="white blog-description">{data.specifications.model}</p> */}
                            {/* <table>
                                <tr>
                                    <td>0 - 100</td><td>Top Speed</td><td> Range</td><td>Efficiency</td><td>Fastcharge</td>
                                </tr>
                                <tr>
                                    <td>{data.specifications.acceleration}</td><td>{data.specifications.topspeed}</td><td>{data.specifications.erange_real}</td><td>{data.specifications.efficiency}</td><td>{data.specifications.fastcharge_speed}</td>
                                </tr>
                            </table> */}
                            
                        </div>
                    </div>
                </article>
            )
        })
    }
}

export default AutoList;