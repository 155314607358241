import React, { useRef } from "react";
import "./menuStyles.css";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import {
	Link,
	useHistory,
} from "react-router-dom";
export default function App() {
	const history = useHistory();
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
	const onClick = () => setIsActive(true);
	const onClose = (path) => {
		history.push(path);
		setIsActive(false);
	}

  	return (
    	<div className="mob-menu pull-right ml-2 mr-1 mt-2">
      		<div className="menu-container">
				<div  onClick={onClick} className="menu-trigger pointer">
					<i className="fa fa-bars sandwitch-btn"></i>
				</div>
				<nav ref={dropdownRef} className={`menu ${isActive ? "active" : "inactive"}`} >
					<ul>
						<li onClick={ () => onClose('/bikes')} >
							<span>Bikes & Scooters</span>
						</li>
						<li onClick={() => onClose('/autos')} >
							<span>Autos</span>
						</li>
						<li onClick={() => onClose('/cars')} >
							<span>Cars</span>
						</li>
						<li onClick={() => onClose('/find-your-chargin-stations')} >
							<span>Charging stations</span>
						</li>
						{/* <li onClick={ () => onClose('cars')} >
							<span>Battery</span>
						</li>
						<li onClick={ () => onClose('cars')} >
							<span>Charging Stations</span>
						</li> */}
					</ul>
       			</nav>
      		</div>
    	</div>
  	);
}
