import React, { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps"


function FindYourChargingStationMarker(props) {
    const [open, setOpen] = useState(false)
    
    const triggerShowInfo = (i) => {
        return () => {
            setOpen(i)
        }
    }
    const handleCloseCall = () => {
        setOpen(false)
    }
    
    return props.chargingStations.map((s, i) => {
        return <Marker
                    title={s.title + ' (' + s.address + ')'}
                    onClick={triggerShowInfo(i)}
                    key={i + 1}
                    position={{ lat: parseFloat(s.location.latitude), lng: parseFloat(s.location.longitude) }}
                >  
                    {open === i &&
                        <InfoWindow onCloseClick={handleCloseCall} >
                            <div style={{maxWidth:220, color: 'black'}} >
                                <div style={{fontWeight: 600}} >{s.title}</div>
                                <div className="mt-2" style={{fontWeight: 400}} >{s.address}</div>
                                <div className="mt-2" style={{fontWeight: 400}} ><a target="_blank" href={"/charging-stations/" + s.uuid} >View Details</a></div>
                            </div>
                        </InfoWindow>
                    }
                </Marker>
        })
              
}

export default FindYourChargingStationMarker;