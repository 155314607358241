import { createStore, applyMiddleware } from 'redux';
//import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { createPromise } from 'redux-promise-middleware'
import { createBrowserHistory } from 'history'

import reducers from '../reducers';

export const history = createBrowserHistory();

const Store = createStore(reducers, applyMiddleware(routerMiddleware(history),logger, createPromise(), thunk));

export default Store;
