import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Space } from 'antd';


function ForgotPassword (props) {

    return (
        <div>
            <div className="mt-2" >
                <Input placeholder="Enter your email" />
            </div>
            
            <div>
                <Button className="mt-2" type="primary">
                    Submit
                </Button>
            </div>
        
            
            <div className="my-5 text-center" ><a onClick={() => props.setpageName('LOGIN')} className="ml-2">Back</a></div>
        </div>
    )
}

export default ForgotPassword;

