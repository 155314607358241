import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FetchShowrooms } from '../../actions/vehicles';
import {
    Link,
    useHistory
} from "react-router-dom";
import Utils from '../../utils';
import Config from '../../ev-config';

function  ShowRooms(props) {
    const history = useHistory();
    const dispatch = useDispatch();
  
    var showrooms = useSelector(store => store.vehicles.showrooms);
    const [index, setIndex] = useState(0)

    
    useEffect(() => {
        if(props.apiFetch !== false) {
            dispatch(FetchShowrooms(props.type))
        }
    }, []);

    if(props.apiFetch === false) {
        showrooms = props.showrooms
    }

    if(showrooms.length === 0 ) {
        return <div></div>
    }

    return (
        <div class="up-events">
            <div class={`${props.fullWidth === true ? 'container-fluid' : 'container'}`}>
                <div className="row py-3" >
                    <div class="col-12">
                        <h4 class="title">Showrooms</h4>
                        <div class="row position-relative ">
                            {index+4 < showrooms.length &&
                            <i onClick={() => setIndex(index + 1)} class="fa fa-chevron-circle-right blox-next-icon pointer" aria-hidden="true"></i>
                            }
                            {index > 0 &&
                            <i onClick={() => setIndex(index - 1)} class="fa fa-chevron-circle-left blox-previous-icon pointer" aria-hidden="true"></i>
                            }
                            {getShowRooms()} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function getShowRooms() {
        var list = []
        for (var i=index; i<index+4 && i < showrooms.length; i++) {
            if(showrooms.length) {
                const a = i
                list.push(
                    <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12 pointer" onClick={() => history.push('/showrooms/' + showrooms[a].uuid)}   >
                        <div className="blog mt-3">
                            <div className="blog-img">
                                <img alt="loading" src={Config.cdnUrl + showrooms[a].image}/>
                            </div>
                            <div class="blog-desc">
                                <div class="blog-title white"><a href="">{showrooms[a].title}</a></div>
                                <p class="white blog-description">{showrooms[a].location.name} {', '}{showrooms[a].location.district}</p>
                            </div>
                        </div>
                    </article>
                )
            }
        }
        return list;
    }
}

export default ShowRooms;