import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Config from '../../ev-config';
import Utils from '../../utils';
import ProfileModalContent from '../header/profile/pageContents';
import { BookTestRide, ClearBookTestRide } from '../../actions/user';
import { Drawer, Form, Button, Alert, Radio, Row, Input, Select, Modal, DatePicker, Space } from 'antd';
const { Option } = Select;


export default function TestRide(props) {
    const dispatch = useDispatch();
    const [vehicleDetails, setVehicleDetails] = useState("")
    const [showroom, setShowroom] = useState("")
    const [timeSlot, setTimeSlot] = useState("9-12")
    const [showError, setShowError] = useState(false)
    var [pageName, setpageName] = useState('LOGIN')
    const user = useSelector(store => store.user);


    function clearAll() {
        setShowroom("")
        setTimeSlot("9-12")
        setShowError(false)
    }
    useEffect(() => {
        clearAll()
        setVehicleDetails("")
        axios.get(`${Config.apiRoot}/vehicles/${props.selvehicle}`).then((resp)=> {
            setVehicleDetails(resp.data)
        })
    }, [props.selvehicle])

    function afterClose() {
        clearAll()
    }
    const handleOk = () => {
        if(!showroom) {
            Utils.showError('Please select a showroom')
            setShowError(true)
        } else {
            const payload = {
                vehicle: props.selvehicle,
                showroom: showroom,
                time_slot: timeSlot
            }
            dispatch(BookTestRide(payload))
        }
    }
    function onChange(value, obj) {
        setShowroom(value)
    }
    function onChangeSlot(e, obj) {
        setTimeSlot(e.target.value)
    }



    if(user.isBookTestRideComplete === 'FULFILLED') {
        props.setshowModal(false)
        dispatch(ClearBookTestRide())
    }
    return (
        <Modal
            title={user.isCheckLoginComplete === 'FULFILLED' ? 'Test ride booking for ' + ( vehicleDetails ? vehicleDetails.title : '') : 'Please login to EVHorse to continue'}
            visible={props.showModal}
            onCancel={() => props.setshowModal(false)}
            cancelButtonProps={{disabled: true, className: 'd-none'}}
            afterClose={() => afterClose()}
            footer={[
                <Button key="back" onClick={() => props.setshowModal(false)}>
                  Cancel
                </Button>,
                user.isCheckLoginComplete === 'FULFILLED' ?
                <Button key="submit" type="primary" onClick={handleOk}>
                  {user.isBookTestRideComplete === 'PENDING' && Utils.loader()} Book now
                </Button> : '',
              ]}
        >
            <div >

                
                {user.isCheckLoginComplete === 'FULFILLED' ?
                    <>
                    {vehicleDetails &&
                        <>  
                            <div className="mb-4"  ><b>Please fill the following fields to book a test drive</b></div>
                        
                            <div className={`mt-2 ${(showError && !showroom) ? 'error' : ''}`} >
                                <div>Showroom</div>
                                <Select
                                    value={showroom || null}
                                    size="large"
                                    showSearch
                                    className="col-12"
                                    placeholder="Select your Showroom"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                >
                                    {
                                        vehicleDetails.showrooms.map((o) => {
                                            if(o.verified) {
                                                return <Option props={o} value={o.uuid}>{o.title}</Option>
                                            }
                                        })
                                    }
                                </Select>
                            </div>

                            <div className="mt-4" > 
                                <div>Select your time slot</div>
                                <Radio.Group onChange={onChangeSlot} value={timeSlot} buttonStyle="solid">
                                    <Radio.Button value="9-12">9 AM to 12 PM</Radio.Button>
                                    <Radio.Button value="12-2">12 PM to 2 PM</Radio.Button>
                                    <Radio.Button value="2-5">2 PM to 5 PM</Radio.Button>
                                </Radio.Group>
                            </div>

                        </>
                    }
                    </>
                : 
                <ProfileModalContent />
                }
            </div>
        </Modal>
    )
}