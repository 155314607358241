import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ClearUserRegister, LogoutUser } from '../../../actions/user';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Space } from 'antd';

import ForgotPassword from './forgotPassword';
import Register from './register';
import Login from './login';
import Utils from '../../../utils';

function ProfileModalContent(props) {
	const dispatch = useDispatch();
	var [pageName, setpageName] = useState('LOGIN')
	const user = useSelector(store => store.user);

    return (
        <div>
            {pageName === 'FORGOT_PSWD' ? 
                <ForgotPassword setpageName={setpageName} />
            :pageName === 'REGISTER' ? 
                <Register  setpageName={setpageName} />
            :
                <Login  setpageName={setpageName} />
            }
        </div>
    )

}

export default ProfileModalContent;

