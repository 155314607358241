import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

  
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import _ from "lodash";
import ImageGallery from 'react-image-gallery';
import { FetchShowroomDetails } from '../actions/vehicles';
import Config from '../ev-config';
import Loading from '../components/loading';

import Vehicles from './category/vehicles';
import MapPicker from 'react-google-map-picker'

const DefaultLocation = { lat: 8.5241, lng: 76.9366};

function ShowroomDetails(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const dispatch = useDispatch();
    let { id } = useParams();
    const showroomDetails = useSelector(store => store.vehicles.showroomDetails);


    // const [defaultLocation, setDefaultLocation] = useState();

//   const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(15);



    useEffect(() => {
        dispatch(FetchShowroomDetails(id))
    }, [id])



    if(!showroomDetails) {
        return <section class="banner"><div class="container-fluid px-0"><Loading /></div></section>
    }

    var images = []
    showroomDetails.preview_images.map((img) => {
        images.push({
            original: Config.cdnUrl + img,
            thumbnail: Config.cdnUrl + img,
        })
    })



    // function handleChangeLocation (lat, lng){
    //     setLocation({lat:lat, lng:lng});
    //   }
      
      function handleChangeZoom (newZoom){
        setZoom(newZoom);
      }
    
    //   function handleResetLocation(){
    //     setDefaultLocation({ ... DefaultLocation});
    //     setZoom(10);
    //   }
  



    return (
        <section class="banner">
		<div class="container-fluid px-0">
			
			<div class="container">
				<div class="heading">
				    <h1 class="heading-title-left"><i onClick={() => window.history.back()} style={{marginRight:13, cursor:"pointer"}} class="fa fa-long-arrow-left" aria-hidden="true"></i>{showroomDetails.title}</h1>
				    {/* <p class="blog-details-date">{showroomDetails.sub_title}</p> */}
				</div>

				<div class="blog-detail-main-img">
                    <ImageGallery items={images} />
                </div>

                <div className="mt-5">
                    {/* <h4 class="title pt-5"></h4> */}
                    <div class="row">
                        <div class="col-lg-6  col-md-12 col-sm-12 col-xs-12">

                            <div class="mb-4">
                                <div><strong>Address</strong> <br/> {showroomDetails.address}</div>
                            </div>
                            <div class="mb-4">
                                <div><strong>Contact Number</strong> <br/> {showroomDetails.contact_number}</div>
                            </div>
                            <div class="mb-4">
                                <div><strong>Email</strong> <br/> {showroomDetails.email}</div>
                            </div>
                            <div class="mb-4">
                                <div><strong>Working Hours</strong> <br/> {showroomDetails.open_time}{' - '}{showroomDetails.close_time}</div>
                            </div>
                        </div>


                        <div class="col-lg-6  col-md-12 col-sm-12 col-xs-12">
                        
                        

                            <MapPicker defaultLocation={{lat: parseFloat(showroomDetails.location.latitude), lng: parseFloat(showroomDetails.location.longitude)}}
                                zoom={zoom}
                                mapTypeId="roadmap"
                                style={{height:'350px'}}
                                // onChangeLocation={handleChangeLocation} 
                                onChangeZoom={handleChangeZoom}
                                apiKey='AIzaSyBb-Vrual4heLKsxWGlXh-hQwCpVjAGmIA'
                            />
  


                        </div>
                    </div>
                </div>
			</div>


            <Vehicles vehicles={showroomDetails.vehicles} />

		</div>
        </section>
        )

}

export default ShowroomDetails;