import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { UserLogin, ResendOtp, ClearResendOtp, VerifyOtp } from '../../../actions/user';
import validator from 'validator';
import { Drawer, Alert, Button, Col, Row, Input, Select, DatePicker, Space } from 'antd';
import Utils from '../../../utils';
import Config from '../../../ev-config';

function Login (props) {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [phoneNumber, setPhoneNumber] = useState("")
    const [otp, setOtp] = useState("")
    const [initialSet, setInitialSet] = useState(false)
    const [timerOn, setTimerOn] = useState(false)

    const user = useSelector(store => store.user);
   
    const triggerLogin = () => {
        var payload = {
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            username: username,
            password: password,
            grant_type: "password",
        }
        dispatch(UserLogin(payload))
    }

    const resendOtp = () => {
        var payload = {
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            mobile: "+91" + phoneNumber,
            uuid: user.checkLoginResp.user
        }
        dispatch(ResendOtp(payload))
    }
    const triggerSetPhoneNumber = (ph) => {
        if((validator.isInt(ph) || !ph) && ph.length < 11 ) setPhoneNumber(ph)
    }

    if(user.isUserLoginComplete === 'FULFILLED' && !initialSet) {
        setPhoneNumber(user.checkLoginResp.mobile.slice(3))
        setInitialSet(true)
        setTimerOn(true)
        timer(120)
    }
    if(user.isResendOtpComplete === 'FULFILLED') {
        dispatch(ClearResendOtp())
        setTimerOn(true)
        timer(120)
    }


    function timer(remaining) {
        var m = Math.floor(remaining / 60);
        var s = remaining % 60;
        
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        if(document.getElementById('timerLogin'))
        document.getElementById('timerLogin').innerHTML = m + ':' + s;
        remaining -= 1;
        if(remaining >= 0) {
          setTimeout(function() {
              timer(remaining);
          }, 1000);
          return;
        } else {
          setTimerOn(false)
        }
    } 

    const verifyOtp = () => {
        var payload = {
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            otp: otp
        }
        dispatch(VerifyOtp(payload))
    }



    return (
        <div>
            {(user.isUserLoginComplete === 'FULFILLED' && !user.checkLoginResp.mobile_verified) ?
            <>
                <div className="mb-4"  ><b>Your mobile number is not verified, We have send an OTP to your registered mobile number. </b></div>
                
                <div className="mt-2" >
                    <Input addonBefore="+91" disabled={timerOn} value={phoneNumber} onChange={(e)=> triggerSetPhoneNumber(e.target.value)}  size="large" placeholder="Mobile" />
                </div>

                <div className="mt-2" >
                    <Input value={otp} onChange={(e)=> setOtp(e.target.value)} size="large" placeholder="Enter your OTP" />
                </div>
                
                <div>
                    <div className="d-flex" >
                        <Button onClick={()=> verifyOtp()}   className="mt-2" type="primary">
                        { user.isVerifyOtpComplete === 'PENDING' && Utils.loader()} Submit
                        </Button>

                        {timerOn ?
                        <div className="mt-3 ml-4" >Time left = <span id="timerLogin"></span></div>
                        :
                        <Button  onClick={() => resendOtp()}  className="mt-2 ml-4" type="primary">
                        { user.isResendOtpComplete === 'PENDING' && Utils.loader()} Resend OTP
                        </Button>
                        }
                    </div>

                    {timerOn &&
                        <Alert
                            className="mt-4"
                            message=""
                            description="An OTP send to your registered mobile number"
                            type="info"
                            showIcon
                        />
                    }
                </div>
            </>

            :
            <>
                <div className="mt-2" >
                    <Input alue={username} onChange={(e)=> setUsername(e.target.value)} size="large" placeholder="Username" />
                </div>
                <div className="mt-2" >
                    <Input value={password} onChange={(e)=> setPassword(e.target.value)} type="password" size="large" placeholder="Password" />
                </div>
            
                <div>
                    <Button onClick={triggerLogin} className="mt-2" type="primary">
                    { user.isUserLoginComplete === 'PENDING' && Utils.loader()} Login
                    </Button>
                </div>
                {/* <div  className="mt-4"  > <a onClick={() => props.setpageName('FORGOT_PSWD')} >Forgot Password?</a></div> */}
            </>
            }
            <div className="my-4 text-center" >
                OR
            </div>
            
            <div>Don’t have an account? <a onClick={() => props.setpageName('REGISTER')} className="ml-2">Signup</a></div>
        </div>
    )
}

export default Login;