import axios from 'axios';
import events from '../event-names';
import Config from '../../ev-config';
import Utils from '../../utils';

export function FetchHomeList(searchKey) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_HOME_LIST,
            payload: axios.get(`${Config.apiRoot}/home?search=${searchKey}`),
        });
    };
}

export function FetchCarList() {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_CAR_LIST,
            payload: axios.get(`${Config.apiRoot}/vehicles?type=car`),
        });
    };
}

export function FetchBikeList() {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_BIKE_LIST,
            payload: axios.get(`${Config.apiRoot}/vehicles?type=bike`),
        });
    };
}

export function FetchAutoList() {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_AUTO_LIST,
            payload: axios.get(`${Config.apiRoot}/vehicles?type=auto`),
        });
    };
}

export function FetchTruckList() {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_TRUCK_LIST,
            payload: axios.get(`${Config.apiRoot}/vehicles?type=truck`),
        });
    };
}

export function FetchMakeList(type) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_MAKE_LIST,
            payload: axios.get(`${Config.apiRoot}/vehiclemake?type=${type}`),
        });
    };
}

export function FetchVehicleDetails(vehicleId) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_SINGLE_VEHICLE_DETAILS,
            payload: axios.get(`${Config.apiRoot}/vehicles/${vehicleId}`),
        });
    };
}

export function FetchEvNews(type) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_EV_NEWS,
            payload: axios.get(`${Config.apiRoot}/news?type=${type}`),
        });
    };
}

export function FetchShowrooms(type) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_SHOWROOMS,
            payload: axios.get(`${Config.apiRoot}/showrooms?location=${Utils.getLocationCode()}&type=${type}`),
        });
    };
}
export function FetchLocations(searchKey) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_LOCATIONS,
            payload: axios.get(`${Config.apiRoot}/locations?search=${searchKey}`),
        });
    };
}

export function FetchEvVideos(type) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_EV_VIDEOS,
            payload: axios.get(`${Config.apiRoot}/videos?type=${type}`),
        });
    };
}

export function FetchChargingStations() {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_CHARGING_STATIONS,
            payload: axios.get(`${Config.apiRoot}/chargingstations?location=${Utils.getLocationCode()}`),
        });
    };
}

export function FetchShowroomDetails(id) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_SHOWROOM_DETAILS,
            payload: axios.get(`${Config.apiRoot}/showrooms/${id}`),
        });
    };
}
export function FetchChargingStationDetails(id) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_CHARGING_STATION_DETAILS,
            payload: axios.get(`${Config.apiRoot}/chargingstations/${id}`),
        });
    };
}

export function FetchBrandVehicleList(vehicleMake) {
    return (dispatch) => {
        dispatch({
            type: events.FETCH_BRAND_VEHICLE_LIST,
            payload: axios.get(`${Config.apiRoot}/vehicles?vehiclemake=${vehicleMake}`),
        });
    };
}


