import axios from 'axios';
import events from '../event-names';
import Config from '../../ev-config';
import Utils from '../../utils';
import apiHeaders from '../../utils/api-headers';


export function CheckLogin() {
    return (dispatch) => {
        dispatch({
            type: events.CHECK_LOGIN,
            payload: axios.get(`${Config.apiRoot}/checklogin`, {
                withCredentials: true,
                xsrfCookieName: 'evrt',
                xsrfHeaderName: 'access-control-allow-headers,access-control-allow-origin,x-requested-with'
            })
        });
    };
}

export function UserLogin(payload) {
    return (dispatch) => {
        var options = {
            method: 'POST',
            withCredentials: true,
            url: Config.apiRoot + '/login',
            data: payload ,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN',
        };

        dispatch({
            type: events.USER_LOGIN,
            payload: axios(options),
        });
    };
}

export function UserRegister(payload) {
    return (dispatch) => {
        var options = {
            method: 'POST',
            url: Config.apiRoot + '/registration',
            data: payload ,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN',
        };

        dispatch({
            type: events.USER_REGISTER,
            payload: axios(options),
        });
    };
}

export function ClearUserRegister() {
    return (dispatch) => {
        dispatch({
            type: events.CLEAR_USER_REGISTER,
            payload: "",
        });
    };
}

export function ResendOtp(payload) {
    return (dispatch) => {
        var options = {
            method: 'POST',
            url: Config.apiRoot + '/resend/otp',
            data: payload ,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN',
        };

        dispatch({
            type: events.RESEND_OPT,
            payload: axios(options),
        });
    };
}
export function ClearResendOtp() {
    return (despatch) => {
        despatch({
            type: events.CLEAR_RESEND_OPT,
            payload: ""
        });
    };
}

export function VerifyOtp(payload) {
    return (dispatch) => {
        var options = {
            method: 'POST',
            withCredentials: true,
            url: Config.apiRoot + '/verify/otp',
            data: payload ,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN',
        };

        dispatch({
            type: events.VERIFY_OPT,
            payload: axios(options),
        });
    };
}

export function BookTestRide(payload) {
    return (despatch) => {
        despatch({
            type: events.BOOK_TEST_RIDE,
            payload: axios.post(`${Config.apiRoot}/book/test-ride`, payload, { ...apiHeaders() }),
        });
    };
}
export function ClearBookTestRide() {
    return (despatch) => {
        despatch({
            type: events.CLEAR_BOOK_TEST_RIDE,
            payload: ""
        });
    };
}


export function LogoutUser() {
    return (despatch) => {
        despatch({
            type: events.LOGOUT_USER,
            payload: axios.delete([Config.apiRoot, '/checklogin'].join(''), { withCredentials: true, ...apiHeaders() }),
        });
    };
}


