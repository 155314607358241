import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/loading';
import {
    Link,
    useHistory,
    useParams,
} from "react-router-dom";
import _ from 'lodash';
import { FetchBrandVehicleList } from '../actions/vehicles';
import Config from '../ev-config';


function BrandVehicleList(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const vehicle = useSelector(store => store.vehicles);

    let { vehiclemake } = useParams();

    
    useEffect(() => {
        dispatch(FetchBrandVehicleList(vehiclemake))
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    })



    const bikes = _.filter(vehicle.brandVehicleList, function(o) { return o.vehicle_type === 'bike' });
    const cars = _.filter(vehicle.brandVehicleList, function(o) { return o.vehicle_type === 'car' });
    const autos = _.filter(vehicle.brandVehicleList, function(o) { return o.vehicle_type === 'auto' });

    return (
        <>
            <section class="main">

            {vehicle.isBrandVehiclesFetchComplete === 'PENDING' &&
                <Loading />
            }

            {vehicle.brandVehicleList.length > 0 &&
                <div class="container-fluid seperator-bottom">  

                    <div class="pt-5"><h1>Ev in {vehiclemake}</h1></div>

                    {bikes.length &&
                        <div class="row py-3">
                            <div class="col-12">
                                <h4 class="title">Two Wheelers</h4>
                                <p class="sub-title">Below are some of the <em>electric bikes and scooters</em> avaliable in the Indian market</p>
                                <div className="row" >
                                    {getArticle(bikes)}  
                                </div>
                                {/* <Link to="/bikes"><div className="view-more" >View More</div></Link> */}
                            </div>

                            
                        </div>
                    }

                    {autos.length &&
                        <div class="row py-3">
                            <div class="col-12">
                                <h4 class="title">Autos</h4>
                                <p class="sub-title">Below are some of the <em>electric autos</em> avaliable in the Indian market</p>
                                <div className="row" >
                                    {getArticle(autos)}  
                                </div>
                                {/* <Link to="/autos"><div className="view-more" >View More</div></Link> */}
                            </div>
                        </div>
                    }

                    {cars.length &&
                        <div class="row py-3">
                            <div class="col-12">
                                <h4 class="title">Electric Cars</h4>
                                <p class="sub-title">Below are some of the <em>electric cars</em> avaliable in the Indian market</p>
                                <div className="row" >
                                    {getArticle(cars)}  
                                </div>
                                {/* <Link to="/cars"><div className="view-more" >View More</div></Link> */}
                            </div>
                        </div>
                    }
                </div>
            }
            </section>
        </>
    )

    function getConceptText(data) {
        if(data.is_upcoming) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Upcoming</span>
        } else if(data.is_concept) {
            return <span className="ml-3" style={{color:'#8fa7ca'}} >Concept</span>
        } 
        
    }
    
    function getArticle(vh) {
        return vh.map((data, i) => {
            return (
                <article className="col-lg-3 col-md-4 col-sm-6 col-xs-12" key={data.uuid} >
                    <div class="blog mt-3">
                        <div class="blog-img">
                            <Link to={`/${data.vehicle_type}s/${data.make}/${data.uuid}/details`}><img src={Config.cdnUrl + data.thumbnail}/></Link>
                        </div>
                        <div class="blog-desc">
                            <div class="blog-title"><a href={`/${data.vehicle_type}s/${data.make}/${data.uuid}/details`}>{data.title}</a> {getConceptText(data)}</div>
                            <p class="blog-date">{data.sub_title}</p>
                          
                            
                        </div>
                    </div>
                </article>
            )
        })
    }
}

export default BrandVehicleList;