import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useHistory,
    Redirect,
} from "react-router-dom";
import Menus from '../components/menu/menu';
import { Input } from 'antd';
import axios from 'axios';
import CarList from './carList';
import TruckList from './truckList';
import VehicleDetails from './vehicleDetails';
import Home from './home';
import Header from './header';
import BikeList from './bikeList';
import ShowroomDetails from "./showroomDetails";
import AutoList from './autoList';
import Utils from '../utils';
import Config from '../ev-config';
import ChargingStationDetails from './chargingStationDetails';
import BrandVehicleList from './brandVehicleList';
import FindYourChargingStation from './findYourChargingStation';
import PrivacyPolicy from './privacyPolicy';
import TermsAndConditions from './termsAndConditions';
import Footer from './footer';
import { CheckLogin } from '../actions/user';


import './commonStyles.css'



function HomePage(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [searchkey, stSearchKey] = useState('')
    const [location , setLocation] = useState(Utils.getLocationObj())

    useEffect(() => {
        dispatch(CheckLogin())
        if(!location) {
            axios.get(`${Config.apiRoot}/get/city`).then((resp)=> {
                localStorage.setItem("EV-LOCATION", JSON.stringify(resp.data));
                setLocation(resp.data)
            })
        }
    }, []);

    return (
        <Router>
            <div>
      
                <Header stSearchKey={stSearchKey} />
  
                
                <section class="banner">
                </section>
                {Utils.getLocationObj() &&
                <Switch>

                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/terms-and-conditions">
                        <TermsAndConditions />
                    </Route>

                    <Route path="/find-your-chargin-stations">
                        <FindYourChargingStation />
                    </Route>

                    <Route path="/brand/:vehiclemake">
                        <BrandVehicleList searchkey={searchkey} />
                    </Route>

                    <Route path="/charging-stations/:id">
                        <ChargingStationDetails />
                    </Route>
                    <Route path="/showrooms/:id">
                        <ShowroomDetails type="bike" />
                    </Route>
                    <Route path="/autos/:brand/:vehicleName/:carId/details">
                        <VehicleDetails type="auto" />
                    </Route>
                    <Route path="/bikes/:brand/:vehicleName/:carId/details">
                        <VehicleDetails type="bike" />
                    </Route>
                    <Route path="/bikes">
                        <BikeList searchkey={searchkey} />
                    </Route>
                    <Route path="/cars/:brand/:vehicleName/:carId/details">
                        <VehicleDetails type="car" />
                    </Route>
                    <Route type="truck" path="/trucks/:brand/:vehicleName/:carId/details">
                        <VehicleDetails type="truck" />
                    </Route>
                    <Route path="/trucks">
                        <TruckList searchkey={searchkey} />
                    </Route>
                    <Route path="/trucks/:brand">
                        <TruckList searchkey={searchkey} />
                    </Route>
                    <Route path="/cars/:brand">
                        <CarList searchkey={searchkey} />
                    </Route>
                    <Route path="/cars">
                        <CarList searchkey={searchkey} />
                    </Route>
                    <Route path="/autos">
                        <AutoList searchkey={searchkey} />
                    </Route>
                    <Route exact path="/">
                        <Home searchkey={searchkey} />
                    </Route>
                    <Redirect push to="/" />
                </Switch>
                }
          
                {/* <section class="home-bottom">
                    <div class="container text-center subscribe">
                        <h2 class="title">Stay in Touch</h2>
                        <p class="sub-title">Sign up for emails on new Electric vehicles,Solar,Hydrogen Fuel Cells Sector.</p>
                        <div class="subscribe-frm">
                        <input type="text" name="email"  placeholder="Email"/>
                        <button id="search">SUBSCRIBE</button>
                        </div>
                    </div>	
                </section> */}
                {Utils.getLocationObj() &&
                    <Footer />
                }
            </div>
        </Router>
    )
}
  
export default HomePage;

  